import React from "react";
import { FunctionComponent, Fragment } from "react";

interface Props {
  text: string;
}

const MultilineText: FunctionComponent<Props> = ({ text }) => (
  <>
    {text.split("\n").map((line, key) => (
      <Fragment key={key}>
        {!!key && <br />}
        {line}
      </Fragment>
    ))}
  </>
);

export default MultilineText;
