import React from "react";
import { FunctionComponent } from "react";
import ClassNames from "classnames";
import { MainMenuItem, PageState, Language } from "../types";
import MainMenuLevelLeftNav from "./MainMenuLevelLeftNav";

interface Props {
  menuItems: MainMenuItem[];
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  menuLevel: number;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  onMenuItemClick: () => void;
}

const MainMenuListLeftNav: FunctionComponent<Props> = ({
  menuItems,
  isPreview,
  languageId,
  pages,
  menuLevel,
  activePagePath,
  fallbackLanguageId,
  onMenuItemClick,
}) =>
  !menuItems.length ? null : (
    <ul
      className={ClassNames("MainMenu__List", {
        MainMenu__ListLevel1: menuLevel === 1,
        MainMenu__ListLevel2up: menuLevel >= 2,
      })}
    >
      {menuItems.map((currentMenuItem) => (
        <MainMenuLevelLeftNav
          key={currentMenuItem.id}
          currentMenuItem={currentMenuItem}
          isPreview={isPreview}
          languageId={languageId}
          parentId={currentMenuItem.id}
          pages={pages}
          menuLevel={menuLevel}
          activePagePath={activePagePath}
          fallbackLanguageId={fallbackLanguageId}
          onMenuItemClick={onMenuItemClick}
        />
      ))}
    </ul>
  );
export default MainMenuListLeftNav;
