import React from "react";
import { FunctionComponent } from "react";

interface Props {}

const PreviewModule: FunctionComponent<Props> = () => (
  <div className="PreviewModule">Modul hier einfügen</div>
);

export default PreviewModule;
