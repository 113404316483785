import React from "react";
import { FunctionComponent, useEffect } from "react";
import ClassNames from "classnames";
import { isIE11 } from "../utils/utils";

interface Props {
  className?: string;
  viewBox: string;
  onIE11: () => void;
  symbolIdPrefix: string;
  glyph: string;
  spriteFileName: string;
}

const SvgUseIcon: FunctionComponent<Props> = ({
  viewBox,
  className,
  onIE11,
  glyph,
  symbolIdPrefix,
  spriteFileName,
}) => {
  useEffect(() => {
    isIE11 && onIE11();
  }, []);

  const id = `#${symbolIdPrefix}-${glyph}`;
  const externalPath = `/svg-sprite/${spriteFileName}${id}`;

  return (
    <span className={ClassNames("Icon", className)}>
      <svg viewBox={viewBox}>
        <use xlinkHref={isIE11 ? id : externalPath} />
      </svg>
    </span>
  );
};

export default SvgUseIcon;
