import React from "react";
import { FunctionComponent } from "react";
import { spriteData } from "../../dist/svg-sprite/separator-icons-data";
import { SeparatorIconName } from "../types";
import { injectSprite, runOnce } from "../utils/utils";
import SvgUseIcon from "./SvgUseIcon";

const { spriteFileName, viewBoxes, symbolIdPrefix } = spriteData;

interface Props {
  className?: string;
  glyph: SeparatorIconName;
}
const injectOnce = runOnce(() => injectSprite(spriteFileName));

const SeparatorIcon: FunctionComponent<Props> = ({ glyph, className }) => (
  <SvgUseIcon
    onIE11={injectOnce}
    className={className}
    viewBox={viewBoxes[glyph]}
    glyph={glyph}
    symbolIdPrefix={symbolIdPrefix}
    spriteFileName={spriteFileName}
  />
);

export default SeparatorIcon;
