import React, { FunctionComponent, useRef } from "react";
import {
  ColorScheme,
  ContainerQueries,
  HighlightsDisplayType,
  HighlightsItem,
  ImageAlignment,
  Language,
  RichEditorState,
  TitleAndSubtitleTextAlign,
} from "../types";
import ImageWithCrop from "./ImageWithCrop";
import ModuleHeadings from "./ModuleHeadings";
import ClassNames from "classnames";
import { style } from "typestyle";
import MultilineText from "./MultilineText";
import ReadMoreCollapse from "./ReadMoreCollapse";
import { EmblaCarouselType } from "embla-carousel";
import Slider from "./Slider";
import SliderArrow from "./SliderArrow";

interface Props {
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  isFirstOnPage: boolean;
  scheme: ColorScheme;
  items: HighlightsItem[];
  collapsedLinesCount: number | undefined;
  languageId: Language;
  richEditor: RichEditorState;
  isPreview: boolean;
  displayType: HighlightsDisplayType;
  activeModuleId: string | undefined;
  transparentSliderArrowBackground: boolean;
  imageAlignment: ImageAlignment;
  maxColumnsCount: number;
  queries: ContainerQueries;
}

const HighlightsLayout6: FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  scheme,
  items,
  collapsedLinesCount,
  languageId,
  richEditor,
  isPreview,
  displayType,
  activeModuleId,
  transparentSliderArrowBackground,
  imageAlignment,
  maxColumnsCount,
  queries,
}) => {
  const titleBackground = scheme.main.background;
  const sliderRef = useRef<EmblaCarouselType>();

  const highlights = items.map(
    ({ id, title, subtitle, picture, imageSettings, buttons, text }) => (
      <article
        key={id}
        className={ClassNames("HighlightsLayout6__Item", {
          "HighlightsLayout6__Item--list": displayType === "list",
        })}
      >
        <div className="HighlightsLayout6__ItemPicture">
          <ImageWithCrop
            aspectRatio={0.6667}
            picture={picture}
            width={1280}
            imageSettings={imageSettings}
            sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
          />
        </div>

        <div className="HighlightsLayout6__ItemContent">
          <div className="HighlightsLayout6__ItemContentText">
            {title && (
              <h3
                className={ClassNames(
                  "HighlightsLayout6__ItemTitle",
                  style({ color: scheme.main.title })
                )}
              >
                <span
                  className={style({
                    backgroundColor: titleBackground,
                    boxShadow:
                      `7px 3px 0 ${titleBackground}, -7px 3px 0 ${titleBackground}, ` +
                      `7px 0 0 ${titleBackground}, -7px 0 0 ${titleBackground}`,
                  })}
                >
                  {title}
                </span>
              </h3>
            )}
            {subtitle && (
              <div className="HighlightsLayout6__ItemSubtitle">
                <MultilineText text={subtitle} />
              </div>
            )}
            {text && (
              <div className="HighlightsLayout6__Description">
                {collapsedLinesCount !== undefined ? (
                  <ReadMoreCollapse
                    backgroundColor={scheme.main.separator}
                    languageId={languageId}
                    collapsedLinesCount={collapsedLinesCount}
                    isEditing={!!richEditor[id]}
                  >
                    {text}
                  </ReadMoreCollapse>
                ) : (
                  text
                )}
              </div>
            )}
          </div>
          {!!buttons.length && (
            <div className="HighlightsLayout6__Buttons">{buttons}</div>
          )}
        </div>
      </article>
    )
  );

  return (
    <div
      className={ClassNames(
        "HighlightsLayout6",
        `HighlightsLayout6--${imageAlignment}`,
        style({ background: scheme.main.separator, color: scheme.main.text }),
        {
          "HighlightsLayout6--columns-2": maxColumnsCount === 2,
        }
      )}
    >
      <div className="Module__Wrapper Module__Wrapper--default-padding">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />

        <div className="HighlightsLayout6__Items">
          {displayType === "slider" && (
            <Slider
              className="HighlightsLayout6__Slider"
              containerClassName="HighlightsLayout6__SliderContainer"
              slideClassName="HighlightsLayout6__Slide"
              isPreview={isPreview}
              sliderEffect="slide"
              sliderRef={sliderRef}
              slidesToShow={queries["Query--xlarge"] ? maxColumnsCount : 1}
              activePreviewSlideId={activeModuleId}
              slideIds={items.map(({ id }) => id)}
            >
              {highlights}
            </Slider>
          )}
          {displayType === "list" && (
            <div className="HighlightsLayout6__List">{highlights}</div>
          )}
          {displayType === "slider" && items.length > 1 && (
            <div className="HighlightsLayout6__SliderArrows">
              <SliderArrow
                className="HighlightsLayout6__SliderArrow HighlightsLayout6__SliderArrow--left"
                iconClassName="HighlightsLayout6__SliderArrowIcon"
                direction="left"
                onClick={() => sliderRef.current?.scrollPrev()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
              <SliderArrow
                className="HighlightsLayout6__SliderArrow HighlightsLayout6__SliderArrow--right"
                iconClassName="HighlightsLayout6__SliderArrowIcon"
                direction="right"
                onClick={() => sliderRef.current?.scrollNext()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HighlightsLayout6;
