import React from "react";
import { FunctionComponent } from "react";
import ConditionalLink from "./ConditionalLink";
import {
  AspectRatio,
  Picture,
  AnchorAttrs,
  ImageSettingsGlobalCropOnly,
} from "../types";
import ImageWithCrop from "./ImageWithCrop";
import MultilineText from "./MultilineText";

interface Props {
  title: string;
  subtitle: JSX.Element | string | null | undefined;
  link?: AnchorAttrs;
  imagesAspectRatio: AspectRatio;
  picture: Picture;
  imageSettings: ImageSettingsGlobalCropOnly | undefined;
  isPreview: boolean;
  tilesCount: number;
}

const MultiBoxTile: FunctionComponent<Props> = ({
  title,
  subtitle,
  link = {},
  picture,
  imagesAspectRatio,
  imageSettings,
  isPreview,
  tilesCount,
}) => {
  return (
    <ConditionalLink
      className="MultiBox__Tile"
      anchorAttrs={link}
      isPreview={isPreview}
      divIfNoLink
    >
      <ImageWithCrop
        aspectRatio={imagesAspectRatio}
        width={1280}
        picture={picture}
        imageSettings={imageSettings}
        sizes={tilesCount > 1 ? "50vw" : "100vw"}
      />
      <div className="MultiBox__Tile__Headings">
        <div className="MultiBox__Tile__Title">{title}</div>
        {!!subtitle && (
          <div className="MultiBox__Tile__Subtitle">
            {typeof subtitle === "string" ? (
              <MultilineText text={subtitle} />
            ) : (
              subtitle
            )}
          </div>
        )}
      </div>
    </ConditionalLink>
  );
};

export default MultiBoxTile;
