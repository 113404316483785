import React from "react";
import { Component } from "react";
import {
  Language,
  MainMenuItem,
  PageState,
  ColorScheme,
  TopHeaderVariant,
  ActionLinks,
  Picture,
} from "../types";
import { Transition } from "react-transition-group";
import ClassNames from "classnames";
import CompactHeaderBar from "./CompactHeaderBar";
import {
  isElementPartiallyInViewport,
  passiveEventIfSupported,
} from "../utils/utils";

interface Props {
  actionLinks: ActionLinks;
  activePagePath: string[];
  hasHeaderImage: boolean;
  isPreview: boolean;
  languageId: Language;
  pages: PageState;
  scheme: ColorScheme;
  topLevelMainMenuItems: MainMenuItem[];
  fallbackLanguageId: Language | undefined;
  topHeaderVariant: TopHeaderVariant;
  pageId: string;
  smallLogo: Picture;
  mainPageUrl: string | undefined;
  stickyEnabled: boolean;
  intersectionTriggerEl: HTMLElement | null;
}

interface State {
  showFixedHeader: boolean;
}

class FixedHeader extends Component<Props, State> {
  private lastScrollTop = 0;
  private observer?: IntersectionObserver;
  private rafId: number | undefined;

  state: State = {
    showFixedHeader: false,
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.handleIntersectionObserver);
    !this.props.isPreview &&
      window.addEventListener(
        "scroll",
        this.onScrollRAF,
        passiveEventIfSupported
      );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevTriggerEl = prevProps.intersectionTriggerEl;
    const triggerEl = this.props.intersectionTriggerEl;
    if (prevTriggerEl !== triggerEl && this.observer && triggerEl) {
      prevTriggerEl && this.observer.unobserve(prevTriggerEl);
      this.observer.observe(triggerEl);
    }

    this.state.showFixedHeader !== prevState.showFixedHeader &&
      document.documentElement.classList.toggle(
        "scroll-padding",
        this.state.showFixedHeader
      );
  }

  handleIntersectionObserver = (entries: IntersectionObserverEntry[]) => {
    if (!entries.length) return;
    const entry = entries[0];

    const isSticky = !entry.isIntersecting;
    this.handleStickySwitch(isSticky);
  };

  handleFixedHeader = (isInViewport: boolean) => {
    const { showFixedHeader } = this.state;

    const scrollTop = window.scrollY;
    const hasScrolledUp = scrollTop < this.lastScrollTop;
    this.lastScrollTop = scrollTop;

    if (isInViewport && !showFixedHeader) return;

    if (isInViewport && showFixedHeader) {
      this.setState({
        showFixedHeader: false,
      });
      return;
    }

    hasScrolledUp &&
      !showFixedHeader &&
      this.setState({
        showFixedHeader: true,
      });

    !hasScrolledUp &&
      showFixedHeader &&
      this.setState({
        showFixedHeader: false,
      });
  };

  handleStickySwitch = (isSticky: boolean) => {
    const { stickyEnabled, isPreview } = this.props;
    if (!stickyEnabled || isPreview) return;

    this.setState({
      showFixedHeader: isSticky,
    });
  };

  onScroll = () => {
    const { stickyEnabled, intersectionTriggerEl } = this.props;

    // If sticky is set to true, it means that it always shows the
    // fixed header, not only on scroll up
    if (stickyEnabled || !intersectionTriggerEl) return;

    const isInViewport = isElementPartiallyInViewport(intersectionTriggerEl);
    this.handleFixedHeader(isInViewport);
  };

  onScrollRAF = () => {
    this.rafId = window.requestAnimationFrame(this.onScroll);
  };

  componentWillUnmount() {
    this.rafId !== undefined && window.cancelAnimationFrame(this.rafId);
    this.observer && this.observer.disconnect();

    !this.props.isPreview &&
      window.removeEventListener("scroll", this.onScrollRAF);
  }

  render() {
    const {
      actionLinks,
      activePagePath,
      hasHeaderImage,
      isPreview,
      languageId,
      pages,
      scheme,
      topLevelMainMenuItems,
      fallbackLanguageId,
      topHeaderVariant,
      pageId,
      smallLogo,
      mainPageUrl,
    } = this.props;

    return (
      <Transition in={this.state.showFixedHeader} timeout={250} mountOnEnter>
        {(state) => (
          <div
            className={ClassNames(
              "HeaderModule__Sticky",
              `HeaderModule__Sticky--${state}`,
              `HeaderModule__Sticky--${topHeaderVariant}`,
              { "HeaderModule__Sticky--fixed": !isPreview }
            )}
          >
            <CompactHeaderBar
              actionLinks={actionLinks}
              activePagePath={activePagePath}
              fallbackLanguageId={fallbackLanguageId}
              hasHeaderImage={hasHeaderImage}
              isPreview={isPreview}
              languageId={languageId}
              mainPageUrl={mainPageUrl}
              pageId={pageId}
              pages={pages}
              scheme={scheme}
              smallLogo={smallLogo}
              topLevelMainMenuItems={topLevelMainMenuItems}
            />
          </div>
        )}
      </Transition>
    );
  }
}

export default FixedHeader;
