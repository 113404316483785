import { registerErrorReporting } from "./utils/errorReporting";

registerErrorReporting();

import React from "react";
import ReactDOM from "react-dom";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import Generator from "./components/Generator";
import { PreloadedState, SystemPageModuleType } from "./types";
import "./stylesheets/client.scss";
import { getTranslatedPage, passiveEventIfSupported } from "./utils/utils";
import { ResizeObserver } from "@juggle/resize-observer";
import "lazysizes";
import { mostModulesByType } from "./utils/mostModulesByType";
import { getPageModules } from "./selectors/modules";

/**
 * Update the scroll position if there are DOM changes
 * (e.g. the Booking South Tyrol Widget has loaded),
 * otherwise it would cause sudden jumps to
 * another scroll position when navigating to a hash link.
 * If the user makes an interaction (e.g. clicking, typing),
 * the scroll position update is aborted.
 */
const maintainAnchorScroll = (idToScrollTo: string) => {
  if (!idToScrollTo) return;
  // Use an attribute selector, not an ID selector to make sure it
  // works with IDs that begin with a number.
  const element = document.querySelector(`[id="${idToScrollTo}"]`);
  if (!element) return;

  const observer = new ResizeObserver(() => {
    element.scrollIntoView(true);
  });

  const interactionEventNames: (keyof WindowEventMap)[] = [
    "blur",
    "click",
    "input",
    "keydown",
    "resize",
    "wheel",
    "touchstart",
  ];

  const onInteraction = () => {
    observer.disconnect();
    interactionEventNames.forEach((name) =>
      window.removeEventListener(name, onInteraction)
    );
  };

  interactionEventNames.forEach((name) =>
    window.addEventListener(name, onInteraction, passiveEventIfSupported)
  );
  observer.observe(document.body);

  // Timeout if loading takes too long
  setTimeout(() => {
    onInteraction();
  }, 10_000);
};

const checkPreloadSupported = () =>
  document.createElement("link").relList?.supports("preload") ?? false;

const main = async () => {
  // Load Google Fonts on browsers which don’t support rel="preload"
  if (!checkPreloadSupported()) {
    const linkEl = document.querySelector<HTMLLinkElement>(
      "#google-fonts-link"
    );

    linkEl && (linkEl.onload = null);
    linkEl && (linkEl.rel = "stylesheet");
  }

  const preloadedStateElement = document.getElementById(
    "preloaded-state"
  ) as HTMLScriptElement;
  const preloadedState: PreloadedState = JSON.parse(
    preloadedStateElement.textContent as string
  );
  const { languageId, pageId, state } = preloadedState;
  const store = configureStore(state);
  const { pages, modules } = store.getState();
  const page = getTranslatedPage(pages, pageId, languageId);
  const rootEl = document.getElementById("root");

  const pageModuleTypes = getPageModules(modules, page.id).map(
    ({ type }) => type
  );

  const systemPageModuleTypes: SystemPageModuleType[] = [
    "ImprintModule",
    "PrivacyModule",
    "TermsModule",
  ];

  const includesSystemPageModule = systemPageModuleTypes.some((moduleType) =>
    pageModuleTypes.includes(moduleType)
  );

  const modulesByType = {
    ...mostModulesByType,
    ...(includesSystemPageModule
      ? (await import("./utils/systemPageModulesByType"))
          .systemPageModulesByType
      : {}),
  };

  // TODO: change this to ReactDOM.hydrate.
  // Warning: every component on the site needs to be checked afterwards
  // if it still works correctly.
  ReactDOM.render(
    <Provider store={store}>
      <Generator modulesByType={modulesByType} page={page} />
    </Provider>,
    rootEl
  );

  window.addEventListener("hashchange", () => {
    maintainAnchorScroll(location.hash.slice(1));
  });

  maintainAnchorScroll(location.hash.slice(1));
};

main();
