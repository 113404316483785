import React from "react";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getActiveSite } from "../../selectors/sites";
import {
  BaseModuleProps,
  ColorScheme,
  QuestionAndAnswerModuleSettings,
  QuestionsAndAnswersModuleSettings,
  RichEditorState,
  StoreState,
  TranslatedModule,
} from "../../types";
import {
  getActiveColorScheme,
  getTranslatedModulesByParentId,
} from "../../utils/utils";
import JsonLd from "../JsonLd";
import ModuleHeadings from "../ModuleHeadings";
import ModuleWithHeadings from "../ModuleWithHeadings";
import QuestionAndAnswerModule from "./QuestionAndAnswerModule";

type Props = BaseModuleProps<QuestionsAndAnswersModuleSettings>;

interface StateProps {
  submodules: TranslatedModule<QuestionAndAnswerModuleSettings>[];
  scheme: ColorScheme;
  richEditor: RichEditorState;
}

type ReduxProps = ConnectedProps<typeof connector>;

const QuestionsAndAnswersModule: FunctionComponent<Props & ReduxProps> = ({
  submodules,
  scheme,
  translatedModule: {
    id: moduleId,
    settings: { textAlign },
    translation: {
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  activeModuleId,
  isPreview,
  pageId,
  queries,
  richEditor,
}) => (
  <ModuleWithHeadings
    className="QuestionsAndAnswersModule"
    id={moduleId}
    colors={{
      background: scheme.main.separator,
      color: scheme.main.text,
    }}
    title={title}
    subtitle={subtitle}
  >
    <div className="Module__Wrapper Module__Wrapper--small-padding">
      <ModuleHeadings
        scheme={scheme}
        isFirstOnPage={isFirstOnPage}
        textAlign={textAlign}
        title={title}
        subtitle={subtitle}
      />

      <dl className="QuestionsAndAnswersModule__List">
        {submodules.map((submodule) => (
          <QuestionAndAnswerModule
            key={submodule.id}
            activeModuleId={activeModuleId}
            isActive={activeModuleId === submodule.id}
            isFirstOnPage={isFirstOnPage}
            isPreview={isPreview}
            pageId={pageId}
            queries={queries}
            translatedModule={submodule}
            scheme={scheme}
            richEditor={richEditor}
          />
        ))}
      </dl>
      <JsonLd data={getMetadata(submodules)} />
    </div>
  </ModuleWithHeadings>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules, colorSchemes, sites, richEditor },
  {
    translatedModule,
    translatedModule: {
      id: moduleId,
      translation: { languageId },
    },
    pageId,
  }
): StateProps => ({
  submodules: getTranslatedModulesByParentId<QuestionAndAnswerModuleSettings>({
    modules,
    languageId,
    pageId,
    parentId: moduleId,
    moduleType: "QuestionAndAnswerModule",
  }),
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
  richEditor,
});

interface MetaQuestion {
  "@type": "Question";
  name: string;
  acceptedAnswer: {
    "@type": "Answer";
    text: string;
  };
}

interface Metadata {
  "@context": "https://schema.org";
  "@type": "FAQPage";
  mainEntity: MetaQuestion[];
}

const getMetadata = (
  submodules: TranslatedModule<QuestionAndAnswerModuleSettings>[]
): Metadata => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: submodules.map(
      ({
        translation: {
          settings: { title, description },
        },
      }) => ({
        "@type": "Question",
        name: title,
        acceptedAnswer: {
          "@type": "Answer",
          text:
            typeof description === "string"
              ? description.replace(/</g, "\\u003c")
              : "",
        },
      })
    ),
  };
};

const connector = connect(mapStateToProps);

export default connector(QuestionsAndAnswersModule);
