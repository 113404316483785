import React from "react";
import { FunctionComponent } from "react";
import {
  ColorScheme,
  PageState,
  Language,
  MainMenuItem,
  ActionLinks,
} from "../types";
import HamburgerMenu from "./HamburgerMenu";
import { style } from "typestyle";
import ClassNames from "classnames";

interface Props {
  scheme: ColorScheme;
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  topLevelMainMenuItems: MainMenuItem[];
  activePagePath: string[];
  actionLinks: ActionLinks;
  fallbackLanguageId: Language | undefined;
}

const MainMenuHamburger: FunctionComponent<Props> = ({
  scheme,
  isPreview,
  pages,
  languageId,
  topLevelMainMenuItems,
  activePagePath,
  fallbackLanguageId,
}) => (
  <div
    className={ClassNames(
      "HeaderModule__MainMenuHamburger",
      style({ background: scheme.main.background })
    )}
  >
    <HamburgerMenu
      activePagePath={activePagePath}
      isPreview={isPreview}
      languageId={languageId}
      pages={pages}
      scheme={scheme}
      topLevelMainMenuItems={topLevelMainMenuItems}
      fallbackLanguageId={fallbackLanguageId}
    />
  </div>
);

export default MainMenuHamburger;
