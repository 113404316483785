import React from "react";
import { FunctionComponent, ReactText } from "react";
import Icon from "./Icon";
import {
  ItemsListItem as ItemsListItemType,
  OnEndDrag,
  OnDrag,
} from "../types";
import ClassNames from "classnames";
import { Language } from "../../server/types";
import ItemsListItem from "./ItemsListItem";
import ItemsListButton from "./ItemsListButton";

interface Props {
  itemType: string;
  onDrag?: OnDrag;
  sortableItems: ItemsListItemType[];
  onEndDrag?: OnEndDrag;
  onChange?: (itemId: ReactText, index: number) => void;
  onTranslate?: (itemId: ReactText, sourceLanguageId: Language) => void;
  onRemove?: (itemId: ReactText, index: number) => void;
}

const ItemTitle: FunctionComponent<{
  title: string;
}> = ({ title }) => (
  <div className="ItemsListItem__Title">
    <span className="ItemsListItem__Caption" title={title}>
      {title}
    </span>
  </div>
);

const ItemsList: FunctionComponent<Props> = ({
  itemType,
  sortableItems,
  onDrag,
  onEndDrag,
  onChange,
  onTranslate,
  onRemove,
}) => {
  const count = sortableItems.length;
  const hasMultiple = count > 1;

  if (!count) {
    return null;
  }

  return (
    <ul className="ItemsList">
      {sortableItems.map(({ id, title, translateFrom, preview }, index) => {
        return (
          <ItemsListItem
            id={String(id)}
            key={id}
            onDrag={onDrag ? onDrag : () => {}}
            onEndDrag={onEndDrag}
            itemType={itemType}
            index={index}
          >
            {(isDragging) => (
              <li
                className={ClassNames("ItemsListItem", {
                  "ItemsListItem--draggable": hasMultiple,
                  "ItemsListItem--semi-transparent": !!translateFrom,
                  "ItemsListItem--dragging": isDragging,
                })}
                key={id}
              >
                <div className="ItemsListItem__Inner">
                  <div className="ItemsListItem__Preview">
                    <div className="ItemsListItem__PreviewInner ItemsListItem__PreviewInner--draggable">
                      {preview ? preview : <ItemTitle title={title} />}
                    </div>
                    {hasMultiple && (
                      <Icon
                        className="ItemsListItem__SortIcon"
                        glyph="unfold-more"
                      />
                    )}
                  </div>
                  {onChange && !translateFrom && (
                    <ItemsListButton
                      title="Bearbeiten"
                      icon="edit"
                      onClick={() => onChange(id, index)}
                    />
                  )}
                  {onTranslate && translateFrom && (
                    <ItemsListButton
                      title="Übersetzen"
                      icon="translate"
                      onClick={() => onTranslate(id, translateFrom)}
                    />
                  )}
                  {onRemove && !translateFrom && (
                    <ItemsListButton
                      title="Löschen"
                      icon="delete"
                      onClick={() => onRemove(id, index)}
                    />
                  )}
                </div>
              </li>
            )}
          </ItemsListItem>
        );
      })}
    </ul>
  );
};

export default ItemsList;
