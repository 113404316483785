/**
 * In preview mode (prop isPreview = true) this component returns a
 * React Router link, otherwise it returns a normal <a> link
 */

import React from "react";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { AnchorAttrs } from "../types";
import { isMailtoOrTelHref } from "../utils/utils";

interface Props {
  isPreview: boolean;
  anchorAttrs: AnchorAttrs;
}

const SmartLink: FunctionComponent<Props> = (props) => {
  const { isPreview, anchorAttrs, children: smartLinkChildren } = props;
  const {
    href,
    children: anchorChildren,
    target,
    ...partialAnchorAttrs
  } = anchorAttrs;
  const children = anchorChildren ? anchorChildren : smartLinkChildren;

  if (isPreview && target !== "_blank" && href && !isMailtoOrTelHref(href)) {
    return (
      <Link {...partialAnchorAttrs} target={target} to={href}>
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <a {...partialAnchorAttrs} target={target} href={href}>
        {children}
      </a>
    );
  }

  return <span {...partialAnchorAttrs}>{children}</span>;
};

export default SmartLink;
