import React from "react";
import {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  MutableRefObject,
  RefObject,
} from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import {
  StoreState,
  WindowState,
  MapModuleSettings,
  Accommodation,
  BaseModuleProps,
  LoadStatus,
} from "../../types";
import ClassNames from "classnames";
import { injectScript } from "../../actions/LoadStates";
import LazyloadWrapper from "../LazyloadWrapper";
import UsercentricsCMPWrapper from "../UsercentricsCMPWrapper";
import { getActiveSite } from "../../selectors/sites";

const getApiUrl = (apiKey: string | null) =>
  "https://maps.googleapis.com/maps/api/js?key=" +
  (apiKey ? apiKey : "AIzaSyChZtaEAxAAs_FfkSMSNy9Y2PDWvVpEr50");

const mapOptions: google.maps.MapOptions = {
  scrollwheel: false,
  styles: [
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit.station",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};

const initMap = ({
  accommodation,
  elRef,
  zoom,
  mapRef,
  markerRef,
  infoWindowRef,
  infoWindowListenerRef,
}: {
  accommodation: Accommodation;
  elRef: RefObject<HTMLDivElement>;
  zoom: number;
  mapRef: MutableRefObject<google.maps.Map | undefined>;
  markerRef: MutableRefObject<google.maps.Marker | undefined>;
  infoWindowRef: MutableRefObject<google.maps.InfoWindow | undefined>;
  infoWindowListenerRef: MutableRefObject<
    google.maps.MapsEventListener | undefined
  >;
}) => {
  if (!elRef.current) return;

  const position = {
    lat: accommodation.latitude,
    lng: accommodation.longitude,
  };

  mapRef.current = new google.maps.Map(elRef.current, {
    ...mapOptions,
    zoom,
    center: position,
  });
  markerRef.current = new google.maps.Marker({
    position,
    map: mapRef.current,
    title: accommodation.name,
  });
  infoWindowRef.current = new google.maps.InfoWindow({
    content: accommodation.name,
  });
  infoWindowListenerRef.current = google.maps.event.addListener(
    markerRef.current,
    "click",
    () => {
      infoWindowRef.current?.open(mapRef.current, markerRef.current);
    }
  );
  infoWindowRef.current.open(mapRef.current, markerRef.current);
};

interface Props extends BaseModuleProps<MapModuleSettings> {}

interface StateProps {
  accommodation: Accommodation | undefined;
  loadStatus: LoadStatus;
  apiKey: string | null;
}

type ReduxProps = ConnectedProps<typeof connector>;

const MapModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule,
  isPreview,
  loadStatus,
  injectScript,
  accommodation,
  isActive,
  apiKey,
}) => {
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const [isLazyloaded, setIsLazyloaded] = useState(false);
  const [isUsercentricsAccepted, setIsUsercentricsAccepted] = useState(
    isPreview
  );
  const elRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<google.maps.Map>();
  const markerRef = useRef<google.maps.Marker>();
  const infoWindowRef = useRef<google.maps.InfoWindow>();
  const infoWindowListenerRef = useRef<google.maps.MapsEventListener>();

  const showTextOverlay = !isMapInitialized && isPreview;
  const apiUrl = getApiUrl(apiKey);

  useEffect(() => {
    if (loadStatus === "loaded" && accommodation) {
      initMap({
        accommodation,
        elRef,
        infoWindowListenerRef,
        infoWindowRef,
        mapRef,
        markerRef,
        zoom: translatedModule.settings.zoom,
      });
      setIsMapInitialized(true);
    }
  }, [loadStatus, accommodation]);

  useEffect(() => {
    mapRef.current?.setZoom(translatedModule.settings.zoom);
  }, [translatedModule.settings.zoom]);

  // On unmount
  useEffect(() => {
    return () => {
      const { google } = (window as unknown) as WindowState;
      if (!google || !google.maps) return;

      // There is currently no way to remove a map instace without creating a memory leak
      // https://stackoverflow.com/questions/21142483/google-maps-js-v3-detached-dom-tree-memory-leak
      infoWindowListenerRef.current &&
        google.maps.event.removeListener(infoWindowListenerRef.current);
      markerRef.current?.setMap(null);
    };
  }, []);

  const loadAPI = () => {
    injectScript(apiUrl);
  };

  useEffect(() => {
    isPreview && isActive && loadAPI();
  }, [isPreview, isActive]);

  useEffect(() => {
    !isPreview &&
      !isActive &&
      isUsercentricsAccepted &&
      isLazyloaded &&
      loadAPI();
  }, [isPreview, isActive, isLazyloaded, isUsercentricsAccepted]);

  return (
    <div
      id={translatedModule.id}
      className={ClassNames("MapModule Module", {
        "MapModule--loaded": isMapInitialized,
      })}
    >
      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <UsercentricsCMPWrapper
          languageId={translatedModule.translation.languageId}
          serviceName="Google Maps"
          onAccepted={setIsUsercentricsAccepted}
          isPreview={isPreview}
        >
          <div className="MapModule__Container" ref={elRef} />
          {showTextOverlay && (
            <div className="MapModule__TextOverlay">
              Klicken Sie hier, um die Karte zu laden.
            </div>
          )}
        </UsercentricsCMPWrapper>
      </LazyloadWrapper>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { accommodation, loadStates, sites },
  { translatedModule }
): StateProps => {
  const apiKey = getActiveSite(sites).googleMapsApiKey;
  const apiUrl = getApiUrl(apiKey);

  return {
    apiKey: getActiveSite(sites).googleMapsApiKey,
    accommodation: accommodation[translatedModule.translation.languageId],
    loadStatus: loadStates.scripts[apiUrl] ?? "unloaded",
  };
};

const mapDispatchToProps = {
  injectScript,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MapModule);
