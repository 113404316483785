import { Component, ComponentClass } from "react";
import { findDOMNode } from "react-dom";
import {
  DragSource,
  DropTarget,
  DragSourceSpec,
  DropTargetSpec,
  XYCoord,
} from "react-dnd";
import { Sortable as SortableType, SortableProps } from "../types";

interface Props extends SortableProps {
  children: (isDragging: boolean) => JSX.Element;
}

interface MonitorItem {
  initialIndex: number;
  index: number;
}

const widgetSource: DragSourceSpec<Props, MonitorItem> = {
  beginDrag({ index }) {
    return { index, initialIndex: index };
  },

  endDrag({ onEndDrag, id }, monitor) {
    if (!monitor || !onEndDrag) return;
    const { initialIndex, index: dropIndex } = monitor.getItem() as MonitorItem;
    onEndDrag(initialIndex, dropIndex, id);
  },
};

const isElement = (e: Element | Text | null): e is Element =>
  e instanceof Element;

const widgetTarget: DropTargetSpec<Props> = {
  hover(props, monitor, component) {
    if (!monitor || !component) return;

    const item = monitor.getItem() as MonitorItem;
    let dragIndex = item.index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    const node = findDOMNode(component);
    if (!isElement(node)) return;

    // Determine rectangle on screen
    const hoverBoundingRect = node.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.onDrag(dragIndex, hoverIndex, props.id);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    item.index = hoverIndex;
  },
};

class ItemsListItem extends Component<Props & SortableType> {
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      isDragging,
      children,
    } = this.props;

    return connectDragSource(connectDropTarget(children(isDragging)));
  }
}

const DropComponent = DropTarget<Props>(
  (props) => props.itemType,
  widgetTarget,
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(ItemsListItem);

const DragDropComponent = (DragSource<Props>(
  (props) => props.itemType,
  widgetSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })
)(DropComponent) as unknown) as ComponentClass<Props>;

export default DragDropComponent;
