import React from "react";
import { FunctionComponent, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { LanguageMenuItem, Language } from "../types";
import Icon from "./Icon";

interface Props {
  isPreview: boolean;
  items: LanguageMenuItem[];
  languageId: Language;
}

const LanguageMenuSelect: FunctionComponent<Props> = ({
  isPreview,
  items,
  languageId,
}) => {
  const languageIds = items.map(({ hrefLang }) => hrefLang);
  const index = languageIds.indexOf(languageId);
  const [selectedIndex, setSelectedIndex] = useState(index);
  const lastSelectedIndex = useRef(index);
  const history = isPreview && useHistory();

  useEffect(() => {
    // Only go to a new URL if a different language was selected
    if (selectedIndex === lastSelectedIndex.current) return;
    lastSelectedIndex.current = selectedIndex;
    const { href } = items[selectedIndex];
    history ? history.push(href) : (location.href = href);
  }, [selectedIndex]);

  return (
    <div className="LanguageMenu__Inner">
      <Icon
        className="LanguageMenu__Icon LanguageMenu__Icon--front"
        glyph="language"
      />
      <span className="LanguageMenu__SelectedItem">
        {items[selectedIndex]?.children}
        <Icon
          className="LanguageMenu__Icon LanguageMenu__Icon--dropdown"
          glyph="arrow-down"
        />
      </span>
      <select
        value={selectedIndex}
        className="LanguageMenu__Select"
        onChange={(e) => {
          const value = +e.target.value;
          value !== selectedIndex && setSelectedIndex(value);
        }}
      >
        {items.map(({ href, children }, index) => {
          return (
            <option
              className="LanguageMenu__SelectOption"
              key={href}
              value={index}
            >
              {children}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default LanguageMenuSelect;
