import React from "react";
import { FunctionComponent } from "react";
import TextFormFieldWithAlign from "./TextFormFieldWithAlign";
import { TitleAndSubtitleTextAlign } from "../types";
import TextAlignSelect from "./TextAlignSelect";
import { getHtmlFieldId } from "../utils/utils";

interface Props {
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  onTextAlignChange: (newTextAlign: TitleAndSubtitleTextAlign) => void;
  onTitleAndSubtitleChange: (data: { title: string; subtitle: string }) => void;
}

const TitleAndSubtitleSettings: FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  onTextAlignChange,
  onTitleAndSubtitleChange,
}) => {
  const subtitleHtmlId = getHtmlFieldId("Untertitel");

  return (
    <>
      <TextFormFieldWithAlign
        label="Titel"
        currentFormValue={title}
        currentTextAlignValue={textAlign.title}
        onTextChange={(value) => {
          onTitleAndSubtitleChange({ title: value, subtitle });
        }}
        onTextAlignChange={(value) => {
          onTextAlignChange({
            title: value,
            subtitle: textAlign.subtitle,
          });
        }}
      />

      <div className="Form__Field">
        <div className="Form__Content Form__Content--compact Form__Content--block">
          <div className="RichEditor">
            <div className="RichEditor__Toolbar">
              <TextAlignSelect
                onChange={(value) => {
                  onTextAlignChange({
                    title: textAlign.title,
                    subtitle: value,
                  });
                }}
                currentValue={textAlign.subtitle}
              />
            </div>
            <textarea
              id={subtitleHtmlId}
              value={subtitle}
              onChange={(e) => {
                onTitleAndSubtitleChange({ subtitle: e.target.value, title });
              }}
              rows={3}
            />
          </div>
        </div>
        <div className="Form__Label">
          <label htmlFor={subtitleHtmlId}>Untertitel</label>
        </div>
      </div>
    </>
  );
};

export default TitleAndSubtitleSettings;
