import React from "react";
import { FunctionComponent } from "react";
import { LanguageMenuItem, Language } from "../types";
import ClassNames from "classnames";
import SmartLink from "./SmartLink";

interface Props {
  items: LanguageMenuItem[];
  isPreview: boolean;
  languageId: Language;
}

const LanguageMenuList: FunctionComponent<Props> = ({
  items,
  isPreview,
  languageId,
}) => (
  <ul className="LanguageMenu__List">
    {items.map(({ children, href, hrefLang, rel }) => (
      <li
        className={ClassNames("LanguageMenu__ListItem", {
          "LanguageMenu__ListItem--active": hrefLang === languageId,
        })}
        key={hrefLang}
      >
        <SmartLink
          isPreview={isPreview}
          anchorAttrs={{
            rel,
            hrefLang,
            href,
            className: "LanguageMenu__Item",
          }}
        >
          {children}
        </SmartLink>
      </li>
    ))}
  </ul>
);

export default LanguageMenuList;
