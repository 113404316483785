import React from "react";
import { FunctionComponent, useEffect, useRef } from "react";
import {
  WindowState,
  QuickEnquiryWidgetSettings,
  WidgetInstance,
} from "../types";
import HgvWidgetContainer from "./HgvWidgetContainer";

interface Props {
  settings: QuickEnquiryWidgetSettings;
}

const QuickEnquiryWidget: FunctionComponent<Props> = ({ settings }) => {
  const ref = useRef<HTMLDivElement>(null);
  const widgetInstanceRef = useRef<WidgetInstance>();

  const initWidget = () => {
    if (!ref.current) return;

    const { BookingSüdtirol } = (window as unknown) as WindowState;
    widgetInstanceRef.current = new BookingSüdtirol.Widgets.QuickEnquiry(
      ref.current,
      settings
    );
    widgetInstanceRef.current.render();
  };

  useEffect(() => {
    return () => {
      widgetInstanceRef.current?.unmount();
    };
  }, []);

  return (
    <HgvWidgetContainer
      widgetInstance={widgetInstanceRef.current}
      initWidget={initWidget}
      settings={settings}
      enableLazyload={false}
    >
      <div className="QuickEnquiryWidget__Container" ref={ref} />
    </HgvWidgetContainer>
  );
};

export default QuickEnquiryWidget;
