import React from "react";
import { FunctionComponent } from "react";
import ClassNames from "classnames";
import { MainMenuItem, PageState, Language } from "../types";
import MainMenuLevelFullOverlayNav from "./MainMenuLevelFullOverlayNav";

interface Props {
  menuItems: MainMenuItem[];
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  onMenuItemClick: () => void;
  menuLevel: number;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
}

const MainMenuListFullOverlayNav: FunctionComponent<Props> = ({
  menuItems,
  isPreview,
  languageId,
  pages,
  onMenuItemClick,
  menuLevel,
  activePagePath,
  fallbackLanguageId,
}) =>
  !menuItems.length ? null : (
    <ul
      className={ClassNames("MainMenu__List", {
        MainMenu__ListLevel0: menuLevel === 0,
        MainMenu__ListLevel1: menuLevel === 1,
        MainMenu__ListLevel2up: menuLevel >= 2,
      })}
    >
      {menuItems.map((currentMenuItem) => (
        <MainMenuLevelFullOverlayNav
          key={currentMenuItem.id}
          currentMenuItem={currentMenuItem}
          isPreview={isPreview}
          languageId={languageId}
          parentId={currentMenuItem.id}
          pages={pages}
          onMenuItemClick={onMenuItemClick}
          menuLevel={menuLevel}
          activePagePath={activePagePath}
          fallbackLanguageId={fallbackLanguageId}
        />
      ))}
    </ul>
  );
export default MainMenuListFullOverlayNav;
