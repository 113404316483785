import React, { FunctionComponent, useRef } from "react";
import {
  AspectRatio,
  ColorScheme,
  ContainerQueries,
  HighlightsDisplayType,
  HighlightsItem,
  TitleAndSubtitleTextAlign,
} from "../types";
import ImageWithCrop from "./ImageWithCrop";
import ModuleHeadings from "./ModuleHeadings";
import ClassNames from "classnames";
import { style } from "typestyle";
import MultilineText from "./MultilineText";
import Slider from "./Slider";
import { EmblaCarouselType } from "embla-carousel";
import SliderArrow from "./SliderArrow";

interface Props {
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  isFirstOnPage: boolean;
  scheme: ColorScheme;
  items: HighlightsItem[];
  queries: ContainerQueries;
  displayType: HighlightsDisplayType;
  activeModuleId: string | undefined;
  isPreview: boolean;
  transparentSliderArrowBackground: boolean;
}

const HighlightsLayout4: FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  scheme,
  items,
  queries,
  displayType,
  activeModuleId,
  isPreview,
  transparentSliderArrowBackground,
}) => {
  const sliderRef = useRef<EmblaCarouselType>();
  const itemsCount = items.length;
  const isSliderEnabled =
    displayType === "slider" && getShouldEnableSlider(queries, itemsCount);

  const highlights = items.map(
    ({ id, title, subtitle, picture, imageSettings, buttons }) => (
      <article key={id} className="HighlightsLayout4__Item">
        <div className="HighlightsLayout4__ItemPicture">
          <ImageWithCrop
            aspectRatio={getAspectRatio(queries)}
            picture={picture}
            width={1280}
            imageSettings={imageSettings}
            sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
          />
          {buttons[0]}
        </div>

        <div className="HighlightsLayout4__ItemContent">
          {title && (
            <h3
              className={ClassNames(
                "HighlightsLayout4__ItemTitle",
                style({
                  color: scheme.main.title,
                })
              )}
            >
              {title}
            </h3>
          )}
          {subtitle && (
            <div className="HighlightsLayout4__ItemSubtitle">
              <MultilineText text={subtitle} />
            </div>
          )}
        </div>
      </article>
    )
  );

  return (
    <div
      className={ClassNames(
        "HighlightsLayout4",
        style({ background: scheme.main.separator, color: scheme.main.text })
      )}
    >
      <div className="Module__Wrapper Module__Wrapper--small-padding">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
        <div
          className={ClassNames(
            "HighlightsLayout4__Items",
            `HighlightsLayout4__Items--count-${itemsCount}`
          )}
        >
          {isSliderEnabled && (
            <Slider
              className="HighlightsLayout4__Slider"
              containerClassName="HighlightsLayout4__SliderContainer"
              slideClassName="HighlightsLayout4__Slide"
              isPreview={isPreview}
              sliderEffect="slide"
              sliderRef={sliderRef}
              slidesToShow={getSlidesToShow(queries)}
              activePreviewSlideId={activeModuleId}
              slideIds={items.map(({ id }) => id)}
            >
              {highlights}
            </Slider>
          )}
          {!isSliderEnabled && (
            <div className="HighlightsLayout4__List">{highlights}</div>
          )}
          {isSliderEnabled && (
            <div className="HighlightsLayout4__SliderArrows">
              <SliderArrow
                className="HighlightsLayout4__SliderArrow HighlightsLayout4__SliderArrow--left"
                iconClassName="HighlightsLayout4__SliderArrowIcon"
                direction="left"
                onClick={() => sliderRef.current?.scrollPrev()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
              <SliderArrow
                className="HighlightsLayout4__SliderArrow HighlightsLayout4__SliderArrow--right"
                iconClassName="HighlightsLayout4__SliderArrowIcon"
                direction="right"
                onClick={() => sliderRef.current?.scrollNext()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getShouldEnableSlider = (
  queries: ContainerQueries,
  highlightsCount: number
) => {
  const isXLarge = queries["Query--xlarge"];
  const isLarge = queries["Query--large"];

  return (
    (isXLarge && highlightsCount > 3) ||
    (!isXLarge && isLarge && highlightsCount > 2) ||
    (!isLarge && highlightsCount > 1)
  );
};

const getSlidesToShow = (queries: ContainerQueries) =>
  queries["Query--xlarge"] ? 3 : queries["Query--large"] ? 2 : 1;

const getAspectRatio = (queries: ContainerQueries): AspectRatio => {
  if (queries["Query--xlarge"]) return 1.2;
  if (queries["Query--medium"]) return 0.75;
  return 1.2;
};

export default HighlightsLayout4;
