import React from "react";
import SmartLink from "./SmartLink";
import { FunctionComponent, ReactNode } from "react";
import {
  LegalNavId,
  StoreState,
  PageLinkParams,
  Language,
  AnchorAttrs,
} from "../types";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import {
  resolveLink,
  checkIsOnePager,
  getFallbackLanguage,
} from "../utils/utils";
import { toggleLegalNav } from "../actions/LegalNav";
import { getActiveSite } from "../selectors/sites";

interface Props {
  legalNavId: LegalNavId;
  isPreview: boolean;
  languageId: Language;
  children?: ReactNode;
  target?: "_blank";
}

interface StateProps {
  anchorAttrs: AnchorAttrs;
  isOnePager: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const LegalNavLink: FunctionComponent<Props & ReduxProps> = ({
  legalNavId,
  isPreview,
  anchorAttrs,
  toggleLegalNav,
  isOnePager,
}) => (
  <SmartLink
    anchorAttrs={
      isOnePager
        ? {
            ...anchorAttrs,
            onClick: (e) => {
              e.preventDefault();
              toggleLegalNav(legalNavId);
            },
          }
        : anchorAttrs
    }
    isPreview={isPreview}
  />
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { sites, pages },
  { legalNavId, isPreview, languageId, children }
): StateProps => {
  const site = getActiveSite(sites);
  const pageId = pages.byAlias[legalNavId];

  const pageLinkParams: PageLinkParams = {
    isPreview,
    languageId,
    pageId,
    pages,
    fallbackLanguageId: getFallbackLanguage(site, languageId),
  };

  const anchorAttrs = resolveLink(pageLinkParams);
  // Warning: don’t remove or change this id attribute.
  // It could be used in a DOM selector in Google Tag Manager.
  const id = `legal-nav-${legalNavId}`;

  return {
    anchorAttrs: children
      ? {
          ...anchorAttrs,
          children,
          ...(isPreview ? {} : { target: "_blank", rel: "noopener" }),
          id,
        }
      : { ...anchorAttrs, id },
    isOnePager: checkIsOnePager(site),
  };
};

const mapDispatchToProps = {
  toggleLegalNav,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(LegalNavLink);
