import React from "react";
import { FunctionComponent } from "react";
import ClassNames from "classnames";
import {
  ColorScheme,
  TranslatedModule,
  ImageModuleSettings,
  ImagesModuleSettings,
} from "../types";
import ImageModule from "./Modules/ImageModule";

interface Props {
  pageId: string;
  images: TranslatedModule<ImageModuleSettings>[];
  isInsideHeader: boolean;
  scheme: ColorScheme;
  isPreview: boolean;
  settings: ImagesModuleSettings["global"];
}

const ImagesSeparator: FunctionComponent<Props> = ({
  images,
  isInsideHeader,
  isPreview,
  scheme,
  settings: { columnsCount, imagesAspectRatio, width },
  pageId,
}) => (
  <div
    className={ClassNames(
      "ImagesSeparator",
      `ImagesSeparator--columns-${columnsCount}`
    )}
  >
    <div
      className={ClassNames(
        "ImagesSeparator__Inner",
        `ImagesSeparator__Inner--width-${width}`
      )}
    >
      {images.map((image) => {
        return (
          <div className="ImagesSeparator__Item" key={image.id}>
            <ImageModule
              aspectRatio={imagesAspectRatio}
              translatedModule={image}
              width={1920}
              sizes={Math.round((1 / columnsCount) * 100) + "vw"}
              lazyLoad={!isInsideHeader}
              scheme={scheme}
              isPreview={isPreview}
              isSlider={false}
              pageId={pageId}
              showOverlay={true}
            />
          </div>
        );
      })}
    </div>
  </div>
);

export default ImagesSeparator;
