import React from "react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  EasiFastCheckInModuleSettings,
  BaseModuleProps,
  StoreState,
  ColorScheme,
} from "../../types";
import { getActiveSite } from "../../selectors/sites";
import { getActiveColorScheme, getSupportedLanguage } from "../../utils/utils";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import ModuleWithHeadings from "../ModuleWithHeadings";
import ModuleHeadings from "../ModuleHeadings";
import ClassNames from "classnames";
import LazyloadWrapper from "../LazyloadWrapper";

type Props = BaseModuleProps<EasiFastCheckInModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const EasiFastCheckInModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign, url },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  isActive,
  isPreview,
}) => {
  const lang = getSupportedLanguage(languageId, ["de", "it", "en"]);
  const queryString =
    typeof location !== "undefined" ? `?parameter=${location.href}` : "";
  const urlWithLang = `${url}/lang/${lang}${queryString}`;

  const ref = useRef<HTMLDivElement>(null);
  const [isLazyloaded, setIsLazyloaded] = useState(false);
  const [iframeHeight, setIframeHeight] = useState(0);

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (!ref.current) return;
      if (typeof event.data === "string") {
        const matches = event.data.match(/^fciResizeIframe:([0-9]+)/);
        const newSize = Number(matches?.[1]);
        newSize &&
          setIframeHeight((lastSize) =>
            newSize !== lastSize ? newSize : lastSize
          );
      }

      if (event.data?.task === "scroll_top") {
        window.scrollTo(0, ref.current.offsetTop);
      }
    };

    window.addEventListener("message", onMessage, false);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="EasiFastCheckInModule"
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div ref={ref} className="Module__Wrapper">
          {!url && (
            <div className="EasiFastCheckInModule__LogoContainer">
              <img
                className="EasiFastCheckInModule__Logo"
                src={
                  isLazyloaded
                    ? "/easi-fast-check-in.svg"
                    : "/transparent-pixel.png"
                }
                alt="easi FastCheckIn"
              />
            </div>
          )}
          {url && (
            <iframe
              scrolling="no"
              name="Fast+Check-in"
              style={{ height: iframeHeight || undefined }}
              className={ClassNames("EasiFastCheckInModule__Iframe", {
                "EasiFastCheckInModule__Iframe--no-click":
                  !isActive && isPreview,
              })}
              src={isLazyloaded ? urlWithLang : undefined}
            ></iframe>
          )}
        </div>
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
});

const connector = connect(mapStateToProps);

export default connector(EasiFastCheckInModule);
