import React from "react";
import { ReactElement } from "react";
import Icon from "./Icon";
import ClassNames from "classnames";
import { RadioOption } from "../types";

interface Props<RadioValue> {
  onChange: (value: RadioValue) => void;
  currentFormValue: RadioValue;
  fieldLabel: string;
  expandButtons?: boolean;
  denseLayout: boolean;
  radios: RadioOption<RadioValue>[];
}

const FormFieldRadios: <RadioValue extends string | number>(
  props: Props<RadioValue>
) => ReactElement<Props<RadioValue>> = ({
  onChange,
  radios,
  currentFormValue,
  fieldLabel,
  expandButtons,
  denseLayout,
}) => (
  <div className="Form__Field">
    <div
      className={ClassNames(
        "Form__Content",
        "Form__Content--wrap",
        "Form__Content--radios",
        {
          "Form__Content--radios-dense": denseLayout,
        }
      )}
    >
      {radios.map(({ title, iconGlyphs, value, label }) => {
        const checked = value.toString() === currentFormValue.toString();
        const classNames = ClassNames("Btn", {
          "Btn--active": checked,
          "Btn--expanded": expandButtons,
        });

        return (
          <label key={value} className={classNames} title={title}>
            <input
              type="radio"
              checked={checked}
              value={value}
              onChange={(event) => {
                const value = event.target.value;
                const float = parseFloat(value);
                const targetValue = isNaN(float) ? value : float;
                onChange(targetValue as typeof currentFormValue);
              }}
            />
            {(Array.isArray(iconGlyphs) ? iconGlyphs : [iconGlyphs]).map(
              (glyph) => (
                <Icon key={glyph} glyph={glyph} />
              )
            )}
            {label && <div className="Btn__Caption">{label}</div>}
          </label>
        );
      })}
    </div>
    <div className="Form__Label">
      <label>{fieldLabel}</label>
    </div>
  </div>
);

export default FormFieldRadios;
