import React from "react";
import { FunctionComponent } from "react";
import SmartLink from "./SmartLink";
import { isPlaceholder } from "../selectors/pictures";
import { ColorScheme, HeaderLogoBackground, Picture } from "../types";
import { style } from "typestyle";
import ClassNames from "classnames";

interface Props {
  isPreview: boolean;
  logo: Picture;
  mainPageUrl: string | undefined;
  scheme: ColorScheme;
  logoBackground?: HeaderLogoBackground;
}

const HeaderLogo: FunctionComponent<Props> = ({
  isPreview,
  logo,
  mainPageUrl,
  logoBackground,
  scheme,
}) => {
  if (isPlaceholder(logo)) return null;
  return (
    <div
      className={ClassNames(
        "HeaderModule__Logo",
        logoBackground &&
          style({
            backgroundColor:
              logoBackground === "color-scheme-background"
                ? scheme.main.background
                : logoBackground,
          })
      )}
    >
      <SmartLink
        anchorAttrs={{
          href: mainPageUrl || "#",
        }}
        isPreview={isPreview}
      >
        <img
          src={logo.url}
          title={logo.title ?? undefined}
          alt={logo.title ?? ""}
        />
      </SmartLink>
    </div>
  );
};

export default HeaderLogo;
