import React from "react";
import { FunctionComponent, useState } from "react";
import isInt from "validator/lib/isInt";
import { getHtmlFieldId } from "../utils/utils";

interface Props {
  value: number | null | undefined;
  allowEmpty: boolean;
  label: string;
  min: number;
  max: number;
  onChange: (value: number) => void;
  errorMessage: string;
  isValid?: boolean;
  forceUpdateValue?: () => number | undefined;
}

const checkValidity = ({
  value,
  min,
  max,
  allowEmpty,
}: {
  value: string;
  min: number;
  max: number;
  allowEmpty: boolean;
}) => {
  if (allowEmpty && value === "") return true;
  return isInt(value, {
    min,
    max,
  });
};

const NumberFormField: FunctionComponent<Props> = ({
  label,
  min,
  max,
  onChange,
  errorMessage,
  isValid: isValidProp = true,
  value,
  allowEmpty,
  forceUpdateValue,
  children,
}) => {
  const initialFormValue =
    value === undefined || value === null ? "" : value.toString();
  const [{ formValue, isValidState }, setState] = useState({
    formValue: initialFormValue,
    isValidState: true,
  });

  const forcedValue = forceUpdateValue && forceUpdateValue();
  if (forcedValue !== undefined) {
    const value = String(forcedValue);
    setState({
      formValue: value,
      isValidState: checkValidity({
        allowEmpty,
        max,
        min,
        value,
      }),
    });
  }

  const htmlId = getHtmlFieldId(label);

  return (
    <div className="Form__Field">
      <input
        id={htmlId}
        type="number"
        min={min}
        max={max}
        value={formValue}
        onChange={(e) => {
          const currentStringValue = e.target.value;
          const currentValue = parseFloat(currentStringValue);
          const newIsValid = checkValidity({
            value: currentStringValue,
            max,
            min,
            allowEmpty,
          });

          setState({
            formValue: isNaN(currentValue) ? "" : currentStringValue,
            isValidState: newIsValid,
          });
          newIsValid && onChange(currentValue);
        }}
      />
      <div className="Form__Label">
        <label htmlFor={htmlId}>{label}</label>
      </div>
      {(!isValidState || !isValidProp) && (
        <div className="Form__Error">{errorMessage}</div>
      )}
      {children}
    </div>
  );
};

export default NumberFormField;
