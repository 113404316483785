import React from "react";
import { FunctionComponent, MouseEvent } from "react";
import { ColorScheme } from "../types";
import Icon from "./Icon";
import { style } from "typestyle";
import ClassNames from "classnames";

interface Props {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  scheme: ColorScheme;
  className: string;
  iconClassName: string;
  direction: "left" | "right";
  transparentBackground: boolean;
}

const SliderArrow: FunctionComponent<Props> = ({
  onClick,
  scheme,
  direction,
  className,
  iconClassName,
  transparentBackground,
}) => (
  <button
    className={ClassNames(
      className,
      style({
        background: transparentBackground
          ? "transparent"
          : scheme.primary.background,
        fill: scheme.primary.text,
      })
    )}
    onClick={onClick}
  >
    <Icon
      className={iconClassName}
      glyph={direction === "left" ? "arrow-left" : "arrow-right"}
    />
  </button>
);

export default SliderArrow;
