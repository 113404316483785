import React from "react";
import { FunctionComponent, useState } from "react";
import {
  Google360VirtualTourModuleSettings,
  BaseModuleProps,
  StoreState,
  ColorScheme,
} from "../../types";
import { getActiveSite } from "../../selectors/sites";
import { getActiveColorScheme } from "../../utils/utils";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import ModuleWithHeadings from "../ModuleWithHeadings";
import ModuleHeadings from "../ModuleHeadings";
import ClassNames from "classnames";
import LazyloadWrapper from "../LazyloadWrapper";

type Props = BaseModuleProps<Google360VirtualTourModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const Google360VirtualTourModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign, url },
    translation: {
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  isActive,
  isPreview,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="Google360VirtualTourModule"
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div className="Module__Wrapper">
          <div className="AspectRatioContainer Google360VirtualTourModule__AspectRatioContainer">
            {url && (
              <iframe
                scrolling="no"
                allowFullScreen={true}
                className={ClassNames(
                  "Google360VirtualTourModule__Iframe",
                  "AspectRatioContainer__Content",
                  {
                    "Google360VirtualTourModule__Iframe--no-click":
                      !isActive && isPreview,
                  }
                )}
                src={isLazyloaded ? url : undefined}
              ></iframe>
            )}
            {!url && (
              <div className="Google360VirtualTourModule__Empty AspectRatioContainer__Content">
                Google 360°
              </div>
            )}
          </div>
        </div>
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
});

const connector = connect(mapStateToProps);

export default connector(Google360VirtualTourModule);
