import React from "react";
import { FunctionComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "../types";
import ColorScheme from "./ColorScheme";
import { duplicateColorScheme } from "../actions/ColorSchemes";
import { ColorSchemesAPI } from "../../server/types";
import { getActiveSite } from "../selectors/sites";

interface Props {
  siteId: string;
  activeId: string | null;
  onSelection: (id: string) => void;
  onEdit: (id: string) => void;
  onSetDefault: () => void;
}

interface StateProps extends ColorSchemesAPI {
  defaultSchemeId: string | null;
}

type ReduxProps = ConnectedProps<typeof connector>;

const ColorSchemeSelection: FunctionComponent<Props & ReduxProps> = ({
  activeId,
  schemes,
  defaultSchemeId,
  siteId,
  duplicateColorScheme,
  onSelection,
  onEdit,
  onSetDefault,
}) => {
  const schemeIds = Object.keys(schemes);
  const schemesExist = !!schemeIds.length;

  return (
    <div className="ColorSchemeSelection Form__Field">
      <div className="Form__Content Form__Content--column">
        {schemeIds.map((id) => (
          <ColorScheme
            key={id}
            id={id}
            scheme={schemes[id]}
            isActive={activeId === id}
            isDefaultScheme={defaultSchemeId === id}
            onSelection={onSelection}
            onDuplicate={() => duplicateColorScheme(siteId, id)}
            onEdit={onEdit}
          />
        ))}
        {schemesExist && (
          <button
            className="Btn Form__Content__Button"
            type="button"
            onClick={() => onSetDefault()}
          >
            Auf Standard zurücksetzen
          </button>
        )}
      </div>
      <div className="Form__Label">
        <label>Farbschemen</label>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  colorSchemes: { schemes, defaultColorScheme },
  sites,
}: StoreState): StateProps => {
  const { colorSchemeId } = getActiveSite(sites);

  return {
    schemes,
    defaultColorScheme,
    defaultSchemeId: colorSchemeId,
  };
};

const mapDispatchToProps = { duplicateColorScheme };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ColorSchemeSelection);
