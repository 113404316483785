import React from "react";
import { FunctionComponent, useState, useRef } from "react";
import {
  PageState,
  Language,
  MainMenuItem,
  LeftOverlayNavIcons,
} from "../types";
import LeftOverlayNavItemTopLevel from "./LeftOverlayNavItemTopLevel";
import LeftOverlaySubmenu from "./LeftOverlaySubmenu";
import { CSSTransition, SwitchTransition } from "react-transition-group";

interface Props {
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  fallbackLanguageId: Language | undefined;
  activePagePath: string[];
  menuItems: MainMenuItem[];
  iconType: LeftOverlayNavIcons;
}

const LeftOverlayNav: FunctionComponent<Props> = ({
  isPreview,
  pages,
  languageId,
  fallbackLanguageId,
  activePagePath,
  menuItems,
  iconType,
}) => {
  const openIndexRef = useRef(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="LeftOverlayNav">
      <SwitchTransition>
        <CSSTransition
          key={String(isOpen)}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="LeftOverlayNav__MenuContainer"
        >
          <div className="LeftOverlayNav__MenuContainer">
            {!isOpen && (
              <div className="LeftOverlayNav__MainMenu">
                <ul className="MainMenu__List MainMenu__ListLevel0">
                  {menuItems.map((menuItem, index) => (
                    <LeftOverlayNavItemTopLevel
                      key={menuItem.id}
                      activePagePath={activePagePath}
                      currentMenuItem={menuItem}
                      fallbackLanguageId={fallbackLanguageId}
                      iconType={iconType}
                      isPreview={isPreview}
                      languageId={languageId}
                      pages={pages}
                      parentId={menuItem.id}
                      onOpen={() => {
                        openIndexRef.current = index;
                        setIsOpen(true);
                      }}
                    />
                  ))}
                </ul>
              </div>
            )}

            {isOpen && (
              <LeftOverlaySubmenu
                activePagePath={activePagePath}
                fallbackLanguageId={fallbackLanguageId}
                isPreview={isPreview}
                languageId={languageId}
                currentMenuItem={menuItems[openIndexRef.current]}
                onClose={() => setIsOpen(false)}
                pages={pages}
              />
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default LeftOverlayNav;
