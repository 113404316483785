import React from "react";
import { FunctionComponent, useState } from "react";
import ClassNames from "classnames";
import { PageState, Language, MainMenuItem } from "../types";
import { getMenuItems } from "../utils/utils";
import SmartLink from "./SmartLink";
import Icon from "./Icon";
import MainMenuListFullOverlayNav from "./MainMenuListFullOverlayNav";

interface Props {
  isPreview: boolean;
  languageId: Language;
  parentId: string;
  pages: PageState;
  currentMenuItem: MainMenuItem;
  onMenuItemClick: () => void;
  menuLevel: number;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
}

const MainMenuLevelFullOverlayNav: FunctionComponent<Props> = ({
  isPreview,
  languageId,
  parentId,
  pages,
  currentMenuItem,
  onMenuItemClick,
  menuLevel,
  activePagePath,
  fallbackLanguageId,
}) => {
  const menuItems = getMenuItems({
    pages,
    pageId: parentId,
    languageId,
    isPreview,
    fallbackLanguageId,
  });
  const hasSubpages = !!menuItems.length;
  const { title, href, id, isFolder, target } = currentMenuItem;
  const activeIndex = activePagePath.indexOf(id);
  const isActive = activeIndex > -1;
  const isSubpageActive = isActive && activeIndex !== activePagePath.length - 1;
  const [isExpanded, setIsExpanded] = useState(isSubpageActive);
  const isFirstMenuLevel = menuLevel === 1;
  const showSubmenu = isExpanded || !isFirstMenuLevel;

  const listItemClassName = ClassNames("MainMenu__ListItem", {
    MainMenu__ListItemLevel0: menuLevel === 0,
    MainMenu__ListItemLevel1: menuLevel === 1,
    MainMenu__ListItemLevel2up: menuLevel >= 2,
  });

  const linkItemClassName = ClassNames("MainMenu__Item", {
    MainMenu__ItemLevel0: menuLevel === 0,
    MainMenu__ItemLevel1: menuLevel === 1,
    MainMenu__ItemLevel2up: menuLevel >= 2,
    "MainMenu__Item--active": isActive,
  });

  const nextMenuLevel = menuLevel + 1;

  const linkContent = <span className="MainMenu__Item__Text">{title}</span>;

  return (
    <li className={ClassNames(listItemClassName)}>
      <div className="MainMenu__ItemWrap">
        {!isPreview && isFolder ? (
          <span
            className={ClassNames(linkItemClassName)}
            onClick={onMenuItemClick}
          >
            {linkContent}
          </span>
        ) : (
          <SmartLink
            isPreview={isPreview}
            anchorAttrs={{
              href,
              target,
              className: linkItemClassName,
              onClick: onMenuItemClick,
            }}
          >
            {linkContent}
          </SmartLink>
        )}

        {isFirstMenuLevel && hasSubpages && (
          <button
            type="button"
            className="MainMenu__ExpandButton"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Icon
              className="MainMenu__ExpandIcon"
              glyph={isExpanded ? "arrow-up" : "arrow-down"}
            />
          </button>
        )}
      </div>

      {showSubmenu && (
        <MainMenuListFullOverlayNav
          isPreview={isPreview}
          languageId={languageId}
          menuItems={menuItems}
          pages={pages}
          onMenuItemClick={onMenuItemClick}
          menuLevel={nextMenuLevel}
          activePagePath={activePagePath}
          fallbackLanguageId={fallbackLanguageId}
        />
      )}
    </li>
  );
};

export default MainMenuLevelFullOverlayNav;
