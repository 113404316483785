import React from "react";
import { FunctionComponent } from "react";
import { TextAlign } from "../types";
import ClassNames from "classnames";
import Icon from "./Icon";
import { textAlignJustify, textAlignments } from "../utils/utils";

interface Props {
  onChange: (value: TextAlign) => void;
  currentValue: TextAlign;
  allowJustify?: boolean;
}

const TextAlignSelect: FunctionComponent<Props> = ({
  onChange,
  currentValue,
  allowJustify = false,
}) => (
  <ul className="RichEditor__Controls">
    {textAlignments
      .concat(allowJustify ? textAlignJustify : [])
      .map(({ title, iconGlyphs, value }) => {
        const checked = value.toString() === currentValue;
        const classNames = ClassNames("Btn", "RichEditor__Btn", {
          "Btn--active": checked,
        });
        return (
          <li key={value}>
            <label className={classNames} title={title}>
              <input
                type="radio"
                checked={checked}
                value={value}
                onChange={(e) => {
                  const value = e.target.value as TextAlign;
                  onChange(value);
                }}
              />
              {Array.isArray(iconGlyphs) ? (
                iconGlyphs.map((glyph) => <Icon key={glyph} glyph={glyph} />)
              ) : (
                <Icon glyph={iconGlyphs} />
              )}
            </label>
          </li>
        );
      })}
  </ul>
);

export default TextAlignSelect;
