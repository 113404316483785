import React from "react";
import { FunctionComponent } from "react";
import { getActiveColorScheme, getSupportedLanguage } from "../../utils/utils";
import { connect, MapStateToProps, ConnectedProps } from "react-redux";
import {
  StoreState,
  ColorScheme,
  EnquiryModuleSettings,
  BaseModuleProps,
  EnquiryWidgetSettings,
} from "../../types";
import ModuleHeadings from "../ModuleHeadings";
import { getActiveSite } from "../../selectors/sites";
import { getEnquiryFieldSettings } from "../../selectors/modules";
import ModuleWithHeadings from "../ModuleWithHeadings";
import EnquiryWidget from "../EnquiryWidget";
import tinycolors from "tinycolor2";

interface Props extends BaseModuleProps<EnquiryModuleSettings> {}

interface StateProps {
  scheme: ColorScheme;
  settings: EnquiryWidgetSettings;
}

type ReduxProps = ConnectedProps<typeof connector>;

const EnquiryModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id: moduleId,
    settings: { textAlign },
    translation: {
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  settings,
}) => {
  // Don’t render anything before accommodation is loaded
  if (!settings.accommodationId) return null;

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={moduleId}
      className="EnquiryModule"
      colors={{
        background: scheme.main.separator,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      {<EnquiryWidget settings={settings} />}
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites, modules },
  { translatedModule }
): StateProps => {
  const site = getActiveSite(sites);

  const {
    translation: { languageId },
    settings: { layout },
  } = translatedModule;

  const scheme = getActiveColorScheme(colorSchemes, site, translatedModule);

  const settings: EnquiryWidgetSettings = {
    fieldSettings: getEnquiryFieldSettings(modules, languageId),
    accommodationId: site.accommodation.id,
    lang: getSupportedLanguage(languageId, ["de", "it", "en", "fr"]),
    layout,
    colorScheme: {
      ...scheme,
      main: {
        ...scheme.main,
        separator: tinycolors(scheme.main.separator).lighten(2).toRgbString(),
      },
    },
  };

  return {
    scheme,
    settings,
  };
};

const connector = connect(mapStateToProps);

export default connector(EnquiryModule);
