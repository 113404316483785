import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  BaseModuleProps,
  ColorScheme,
  QuestionAndAnswerModuleSettings,
  RichEditorState,
} from "../../types";
import RichEditorWrapper from "../RichEditorWrapper";
import { CSSTransition } from "react-transition-group";
import Icon from "../Icon";
import { style } from "typestyle";
import ClassNames from "classnames";

interface Props extends BaseModuleProps<QuestionAndAnswerModuleSettings> {
  scheme: ColorScheme;
  richEditor: RichEditorState;
}

const QuestionAndAnswerModule: FunctionComponent<Props> = ({
  translatedModule,
  translatedModule: {
    id: moduleId,
    translation: {
      languageId,
      settings: { title },
    },
    settings: { textAlign },
  },
  pageId,
  isPreview,
  scheme,
  isActive,
  richEditor,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState<number>();
  const isRichEditorOpen = !!richEditor[moduleId];

  useEffect(() => {
    (isActive || isRichEditorOpen) && setIsExpanded(true);
  }, [isActive, isRichEditorOpen]);

  return (
    <>
      <dt
        className={ClassNames(
          "QuestionsAndAnswersModule__Question",
          style({
            color: scheme.main.title,
            backgroundColor: scheme.main.background,
          })
        )}
      >
        <button
          className="QuestionsAndAnswersModule__QuestionBtn Btn--bare"
          type="button"
          aria-expanded={isExpanded}
          aria-controls={moduleId}
          onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
        >
          <span className="QuestionsAndAnswersModule__QuestionBtnInner">
            <span
              className={`QuestionsAndAnswersModule__QuestionText TextAlign--${textAlign.title}`}
            >
              {title}
            </span>
            <Icon
              className="QuestionsAndAnswersModule__Icon"
              glyph={isExpanded ? "arrow-up" : "arrow-down"}
            />
          </span>
        </button>
      </dt>
      <CSSTransition
        in={isExpanded}
        timeout={1000}
        classNames={{
          enter: "QuestionsAndAnswersModule__Answer--entering",
          enterDone: "QuestionsAndAnswersModule__Answer--entered",
          exitActive: "QuestionsAndAnswersModule__Answer--exiting",
          exitDone: "QuestionsAndAnswersModule__Answer--exited",
        }}
        className={ClassNames(
          "QuestionsAndAnswersModule__Answer",
          style({
            borderColor: scheme.main.title,
            backgroundColor: scheme.main.background,
          })
        )}
        onEnter={(node: HTMLElement) => setHeight(node.scrollHeight)}
        onEntered={() => setHeight(undefined)}
        onExit={(node: HTMLElement) => setHeight(node.scrollHeight)}
        onExiting={() => setHeight(undefined)}
        addEndListener={(node: HTMLElement, done: EventListener) =>
          node.addEventListener("transitionend", done, false)
        }
      >
        <dd id={moduleId} style={{ height }}>
          <RichEditorWrapper
            className={`QuestionsAndAnswersModule__AnswerText TextAlign--${textAlign.description}`}
            module={translatedModule}
            pageId={pageId}
            languageId={languageId}
            isPreview={isPreview}
            scheme={scheme}
          />
        </dd>
      </CSSTransition>
    </>
  );
};

export default QuestionAndAnswerModule;
