import React from "react";
import { FunctionComponent } from "react";
import { AnchorAttrs } from "../types";
import SmartLink from "./SmartLink";

interface Props {
  className?: string;
  anchorAttrs: AnchorAttrs;
  divIfNoLink?: boolean;
  isPreview: boolean;
}

const ConditionalLink: FunctionComponent<Props> = ({
  children,
  anchorAttrs,
  className,
  divIfNoLink = false,
  isPreview,
}) => {
  const raw = <>{children}</>;
  const div = <div className={className}>{children}</div>;

  const { href } = anchorAttrs;

  if (!href) {
    return divIfNoLink ? div : raw;
  }

  return (
    <SmartLink
      anchorAttrs={{ ...anchorAttrs, className, children }}
      isPreview={isPreview}
    />
  );
};

export default ConditionalLink;
