import React, { FunctionComponent, useRef } from "react";
import {
  AspectRatio,
  ColorScheme,
  ContainerQueries,
  HighlightsItem,
  TitleAndSubtitleTextAlign,
} from "../types";
import ImageWithCrop from "./ImageWithCrop";
import ModuleHeadings from "./ModuleHeadings";
import ClassNames from "classnames";
import { style } from "typestyle";
import MultilineText from "./MultilineText";
import { EmblaCarouselType } from "embla-carousel";
import Slider from "./Slider";
import SliderArrow from "./SliderArrow";

interface Props {
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  isFirstOnPage: boolean;
  scheme: ColorScheme;
  items: HighlightsItem[];
  isPreview: boolean;
  queries: ContainerQueries;
  activeModuleId: string | undefined;
  transparentSliderArrowBackground: boolean;
}

const HighlightsLayout9: FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  scheme,
  items,
  isPreview,
  queries,
  activeModuleId,
  transparentSliderArrowBackground,
}) => {
  const sliderRef = useRef<EmblaCarouselType>();

  return (
    <div
      className={ClassNames(
        "HighlightsLayout9",
        style({ background: scheme.main.background, color: scheme.main.text })
      )}
    >
      <div className="Module__Wrapper Module__Wrapper--small-padding">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <div
        className={ClassNames(
          "HighlightsLayout9__Items",
          style({
            $nest: { "&::before": { background: scheme.main.separator } },
          })
        )}
      >
        <div className="HighlightsLayout9__ItemsInner Module__Wrapper">
          <Slider
            className="HighlightsLayout9__Slider"
            containerClassName="HighlightsLayout9__SliderContainer"
            slideClassName="HighlightsLayout9__Slide"
            isPreview={isPreview}
            sliderEffect="slide"
            sliderRef={sliderRef}
            slidesToShow={1}
            activePreviewSlideId={activeModuleId}
            slideIds={items.map(({ id }) => id)}
          >
            {items.map(
              (
                { id, title, subtitle, picture, imageSettings, buttons },
                index
              ) => {
                const prevTitle =
                  items[(index === 0 ? items.length : index) - 1].title;
                const nextTitle =
                  items[index === items.length - 1 ? 0 : index + 1].title;
                const backgroundAndTitleStyle = style({
                  background: scheme.main.background,
                  color: scheme.main.title,
                });

                return (
                  <article key={id} className="HighlightsLayout9__Item">
                    <div
                      className={ClassNames(
                        "HighlightsLayout9__NavTitle",
                        "HighlightsLayout9__NavTitle--left",
                        backgroundAndTitleStyle
                      )}
                    >
                      {prevTitle}
                    </div>

                    <div className="HighlightsLayout9__ItemMain">
                      <div className="HighlightsLayout9__ItemPicture">
                        <ImageWithCrop
                          aspectRatio={getAspectRatio(queries)}
                          picture={picture}
                          width={1280}
                          imageSettings={imageSettings}
                          sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
                        />
                      </div>

                      <div
                        className={ClassNames(
                          "HighlightsLayout9__ItemContent",
                          style({ background: scheme.main.background })
                        )}
                      >
                        {title && (
                          <h3
                            className={ClassNames(
                              "HighlightsLayout9__ItemTitle",
                              style({
                                color: scheme.main.title,
                              })
                            )}
                          >
                            {title}
                          </h3>
                        )}
                        {subtitle && (
                          <div className="HighlightsLayout9__ItemSubtitle">
                            <MultilineText text={subtitle} />
                          </div>
                        )}
                      </div>

                      {!!buttons.length && (
                        <div className="HighlightsLayout9__Buttons">
                          {buttons}
                        </div>
                      )}
                    </div>

                    <div
                      className={ClassNames(
                        "HighlightsLayout9__NavTitle",
                        "HighlightsLayout9__NavTitle--right",
                        backgroundAndTitleStyle
                      )}
                    >
                      {nextTitle}
                    </div>
                  </article>
                );
              }
            )}
          </Slider>
          {items.length > 1 && (
            <div className="HighlightsLayout9__SliderArrows">
              <SliderArrow
                className="HighlightsLayout9__SliderArrow HighlightsLayout9__SliderArrow--left"
                iconClassName="HighlightsLayout9__SliderArrowIcon"
                direction="left"
                onClick={() => sliderRef.current?.scrollPrev()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
              <SliderArrow
                className="HighlightsLayout9__SliderArrow HighlightsLayout9__SliderArrow--right"
                iconClassName="HighlightsLayout9__SliderArrowIcon"
                direction="right"
                onClick={() => sliderRef.current?.scrollNext()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getAspectRatio = (queries: ContainerQueries): AspectRatio =>
  queries["Query--medium"] ? 0.6667 : 1.2;

export default HighlightsLayout9;
