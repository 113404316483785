import React from "react";
import { FunctionComponent, useEffect, useRef } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getActiveSite } from "../../selectors/sites";
import {
  ACSmartVoucherModuleSettings,
  BaseModuleProps,
  ColorScheme,
  Language,
  StoreState,
} from "../../types";
import { getActiveColorScheme, getSupportedLanguage } from "../../utils/utils";
import Icon from "../Icon";
import ModuleHeadings from "../ModuleHeadings";
import ModuleWithHeadings from "../ModuleWithHeadings";
import ClassNames from "classnames";
import { style } from "typestyle";

type Props = BaseModuleProps<ACSmartVoucherModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const languageMapping: { [key in Language]?: 1 | 2 | 3 } = {
  de: 1,
  it: 2,
  en: 3,
};

const getIframeUrl = ({
  cssUrl,
  languageNumber,
  kID,
}: {
  cssUrl: string;
  kID: string;
  languageNumber: 1 | 2 | 3;
}) =>
  "https://voucher.ariescreative.com/widget/voucher.php" +
  `?kID=${kID}&lng=${languageNumber}&css=${cssUrl}`;

const ACSmartVoucherModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    id: moduleId,
    translation: {
      languageId,
      settings: { title, subtitle },
    },
    settings: { textAlign, cssUrl, kID },
  },
  scheme,
  isFirstOnPage,
  isPreview,
  isActive,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const supportedLanguage = getSupportedLanguage(languageId, [
    "de",
    "it",
    "en",
  ]);
  const languageNumber = languageMapping[supportedLanguage];

  const requiredFieldsFilled = Boolean(cssUrl && kID);
  const hasTitleOrSubtitle = Boolean(title || subtitle);

  useEffect(() => {
    if (!requiredFieldsFilled) {
      return;
    }

    const script = document.createElement("script");
    script.src = "https://voucher.ariescreative.com/widget/js/smartvoucher.js";
    script.async = true;
    script.type = "text/javascript";
    ref.current?.insertAdjacentElement("beforeend", script);
  }, [requiredFieldsFilled, hasTitleOrSubtitle]);

  return (
    <ModuleWithHeadings
      className="ACSmartVoucherModule"
      id={moduleId}
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
      title={title}
      subtitle={subtitle}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>
      <div
        className={ClassNames(
          "ACSmartVoucherModule__Content",
          "Module__Wrapper"
        )}
        ref={ref}
      >
        {requiredFieldsFilled && languageNumber !== undefined ? (
          <iframe
            className={ClassNames("ACSmartVoucherModule__Iframe", {
              "ACSmartVoucherModule__Iframe--no-click": !isActive && isPreview,
            })}
            id="voucher"
            src={getIframeUrl({ cssUrl, kID, languageNumber })}
          ></iframe>
        ) : (
          <Icon
            glyph="vouchers"
            className={ClassNames(
              "ACSmartVoucherModule__Icon",
              style({ fill: scheme.main.text })
            )}
          />
        )}
      </div>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
});

const connector = connect(mapStateToProps);

export default connector(ACSmartVoucherModule);
