import React from "react";
import { FunctionComponent, useState, useRef } from "react";
import Icon from "./Icon";
import ClassNames from "classnames";
import { ColorScheme, PageState, Language, MainMenuItem } from "../types";
import { getTranslations } from "../utils/utils";
import translations from "../i18n";
import { style } from "typestyle";
import HamburgerMenuList from "./HamburgerMenuList";

interface Props {
  scheme: ColorScheme;
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  topLevelMainMenuItems: MainMenuItem[];
  activePagePath: string[];
  className?: string;
  fallbackLanguageId: Language | undefined;
}

const HamburgerMenu: FunctionComponent<Props> = ({
  scheme,
  isPreview,
  pages,
  languageId,
  topLevelMainMenuItems,
  activePagePath,
  className,
  fallbackLanguageId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  if (!topLevelMainMenuItems.length) return null;

  const i18n = getTranslations(languageId, translations);

  return (
    <div
      className={ClassNames("HeaderModule__HamburgerMenu", className)}
      ref={ref}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        title={i18n.menu}
        className={ClassNames(
          "HeaderModule__MainMenuHamburger__MenuButton Button",
          style({
            borderColor: scheme.primary.background,
            background: scheme.primary.background,
            color: scheme.primary.text,
          })
        )}
      >
        <Icon
          className={ClassNames(
            "HeaderModule__MainMenuHamburger__MenuIcon",
            style({
              fill: scheme.primary.text,
            })
          )}
          glyph="view-headline"
        />
      </button>

      <HamburgerMenuList
        activePagePath={activePagePath}
        fallbackLanguageId={fallbackLanguageId}
        isOpen={isOpen}
        isPreview={isPreview}
        languageId={languageId}
        pages={pages}
        scheme={scheme}
        topLevelMainMenuItems={topLevelMainMenuItems}
        outerRef={ref}
        onClose={() => setIsOpen(false)}
        showMenuCaption={false}
      />
    </div>
  );
};

export default HamburgerMenu;
