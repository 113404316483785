import React, { FunctionComponent } from "react";
import ImageWithCrop from "./ImageWithCrop";
import MultilineText from "./MultilineText";
import ConditionalLink from "./ConditionalLink";
import { HighlightsItem, HighlightsLayout5AspectRatio } from "../types";

interface Props {
  isPreview: boolean;
  item: HighlightsItem;
  aspectRatio: HighlightsLayout5AspectRatio;
}

const HighlightsLayout5Item: FunctionComponent<Props> = ({
  isPreview,
  item: { id, imageSettings, link, subtitle, title, picture },
  aspectRatio,
}) => {
  return (
    <ConditionalLink
      key={id}
      className="HighlightsLayout5__Item"
      anchorAttrs={link ?? {}}
      isPreview={isPreview}
      divIfNoLink={true}
    >
      <div className="HighlightsLayout5__ItemPicture">
        <ImageWithCrop
          aspectRatio={aspectRatio}
          picture={picture}
          width={1280}
          imageSettings={imageSettings}
          sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
        />
      </div>

      <div className="HighlightsLayout5__ItemContent">
        {title && <h3 className="HighlightsLayout5__ItemTitle">{title}</h3>}
        {subtitle && (
          <div className="HighlightsLayout5__ItemSubtitle">
            <MultilineText text={subtitle} />
          </div>
        )}
      </div>
    </ConditionalLink>
  );
};

export default HighlightsLayout5Item;
