import React from "react";
import { FunctionComponent, useRef, useState } from "react";
import {
  BaseModuleProps,
  ImageGalleryModuleSettings,
  StoreState,
  ColorScheme,
  I18n,
  ImageModuleSettings,
  TranslatedModule,
} from "../../types";
import {
  getActiveColorScheme,
  getGalleryImageAspectRatio,
  getTranslations,
  getModulesByParentId,
  getPlaceholderImage,
  getTranslatedModule,
} from "../../utils/utils";
import { connect } from "react-redux";
import ModuleHeadings from "../ModuleHeadings";
import PhotoSwipeWrapper from "../PhotoSwipeWrapper";
import { Item as PhotoSwipeItem } from "photoswipe";
import Icon from "../Icon";
import globalTranslations from "../../i18n";
import { getActiveSite } from "../../selectors/sites";
import {
  getPicturesById,
  getPictureWithSizedUrl,
} from "../../selectors/pictures";
import ImageModule from "./ImageModule";
import ModuleWithHeadings from "../ModuleWithHeadings";
import ClassNames from "classnames";
import { style } from "typestyle";

interface Props extends BaseModuleProps<ImageGalleryModuleSettings> {}

interface StateProps {
  photoSwipeItems: PhotoSwipeItem[];
  mediaLibraryLoaded: boolean;
  scheme: ColorScheme;
  i18n: I18n;
  images: TranslatedModule<ImageModuleSettings>[];
}

const ImageGalleryModule: FunctionComponent<Props & StateProps> = ({
  translatedModule,
  translatedModule: {
    settings,
    translation: {
      settings: { title, subtitle },
    },
  },
  mediaLibraryLoaded,
  photoSwipeItems,
  scheme,
  isFirstOnPage,
  isPreview,
  i18n,
  images,
  pageId,
}) => {
  const openPhotoSwipeRef = useRef<(startIndex: number) => void>();
  const [maxImagesToShow, setMaxImagesToShow] = useState(5);

  if (!mediaLibraryLoaded) return null;
  const showMoreButton = maxImagesToShow < images.length;
  const shownImages = images.slice(0, maxImagesToShow);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={translatedModule.id}
      className="ImageGalleryModule"
      colors={{ background: scheme.main.background }}
    >
      <div className="Module__Wrapper Module__Wrapper--small-padding">
        <ModuleHeadings
          textAlign={settings.textAlign}
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          title={title}
          subtitle={subtitle}
        />

        <div className="ImageGalleryModule__Wrapper">
          {shownImages.map((imageModule, index) => {
            const item = photoSwipeItems[index];
            return (
              <a
                href={isPreview || !item ? undefined : item.src}
                className="ImageGalleryModule__Item"
                key={imageModule.id}
                onClick={(e) => {
                  e.preventDefault();
                  if (isPreview || !item) return;

                  openPhotoSwipeRef.current?.(index);
                }}
              >
                <ImageModule
                  aspectRatio={getGalleryImageAspectRatio(index)}
                  sizes="50vw"
                  lazyLoad={true}
                  width={1920}
                  translatedModule={imageModule}
                  isPreview={isPreview}
                  isSlider={false}
                  pageId={pageId}
                  scheme={scheme}
                  showOverlay={false}
                />
              </a>
            );
          })}
        </div>
        {showMoreButton && (
          <div className="ImageGalleryModule__ButtonWrap">
            <button
              className={ClassNames(
                "Btn--bare",
                "ImageGalleryModule__Button",
                style({
                  color: scheme.primary.background,
                })
              )}
              onClick={() => setMaxImagesToShow(maxImagesToShow + 8)}
              title={i18n.more}
            >
              <Icon className="ImageGalleryModule__Icon" glyph="arrow-down" />
            </button>
          </div>
        )}
      </div>

      <PhotoSwipeWrapper
        openPhotoSwipeRef={openPhotoSwipeRef}
        items={photoSwipeItems}
      />
    </ModuleWithHeadings>
  );
};

const mapStateToProps = (
  { modules, mediaLibrary, loadStates, colorSchemes, sites }: StoreState,
  { translatedModule, pageId }: Props
): StateProps => {
  const site = getActiveSite(sites);
  const {
    translation: { languageId },
  } = translatedModule;

  const images = getModulesByParentId<ImageModuleSettings>(
    modules,
    translatedModule.id,
    pageId,
    "ImageModule"
  ).map((module) => getTranslatedModule(module, languageId));

  const pictureIds = images.map(
    (imageModule) => imageModule.settings.pictureId
  );

  const photoSwipeItems: PhotoSwipeItem[] = !images.length
    ? []
    : getPicturesById(mediaLibrary.pictures, pictureIds)
        .map(
          getPictureWithSizedUrl({
            width: 1920,
            height: 1080,
            mode: 4,
          })
        )
        .map(({ url, title }) => ({
          src: url,
          w: 1920,
          h: 1080,
          title,
        }));

  const scheme = getActiveColorScheme(colorSchemes, site, translatedModule);
  const i18n = getTranslations(languageId, globalTranslations);

  return {
    images: images.length
      ? images
      : new Array(5)
          .fill(true)
          .map(() => getPlaceholderImage(site.id, pageId, languageId)),
    photoSwipeItems,
    mediaLibraryLoaded: loadStates.mediaLibrary.pictures === "loaded",
    scheme,
    i18n,
  };
};

export default connect(mapStateToProps)(ImageGalleryModule);
