import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  BaseModuleProps,
  MTSToursModuleSettings,
  StoreState,
  ColorScheme,
} from "../../types";
import { MapStateToProps, connect, ConnectedProps } from "react-redux";
import ClassNames from "classnames";
import ModuleHeadings from "../ModuleHeadings";
import { getActiveSite } from "../../selectors/sites";
import { getActiveColorScheme, getSupportedLanguage } from "../../utils/utils";
import { injectScript, loadScript } from "../../actions/LoadStates";
import ModuleWithHeadings from "../ModuleWithHeadings";
import LazyloadWrapper from "../LazyloadWrapper";

type Props = BaseModuleProps<MTSToursModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const MTSToursModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    id,
    translation: {
      languageId,
      settings: { title, subtitle },
    },
    settings: { user, archive, textAlign },
  },
  scheme,
  isFirstOnPage,
  injectScript,
  loadScript,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);

  const tmtsLanguage = getSupportedLanguage(languageId, ["de", "it", "en"]);
  const scriptUrl = `https://tours.t-mts.com/${user}/${archive}/api.js`;

  useEffect(() => {
    isLazyloaded && user && archive && injectScript(scriptUrl);
  }, [user, archive, isLazyloaded]);

  // The script needs to be loaded again and the widget script removed,
  // otherwise the widget won’t load again when navigating pages in the CMS
  useEffect(
    () => () => {
      loadScript(scriptUrl, "unloaded");
      const scriptToRemove = document.querySelector(
        `#js-${user}-${archive}-tmts-widget`
      );

      scriptToRemove &&
        scriptToRemove.parentNode &&
        scriptToRemove.parentNode.removeChild(scriptToRemove);
    },
    []
  );

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="MTSToursModule"
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>
      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div
          className={ClassNames("Module__Wrapper MTSToursModule__Content", {
            "MTSToursModule__Content--empty": !(user && archive),
          })}
        >
          {user && archive && (
            <div
              data-tmts-user={user}
              data-tmts-archive={archive}
              data-tmts-language={tmtsLanguage}
            ></div>
          )}
        </div>
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapDispatchToProps = {
  injectScript,
  loadScript,
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => {
  const site = getActiveSite(sites);

  return {
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MTSToursModule);
