import React from "react";
import { FunctionComponent, useState, RefCallback } from "react";
import {
  TranslatedModule,
  ImagesModuleSettings,
  ContainerQueries,
  ColorScheme,
  Accommodation,
  Picture,
  StoreState,
  PageState,
  Language,
  MainMenuItem,
  ActionLinks,
  HeaderLogoBackground,
  HeaderImageOverlayGradient,
} from "../types";
import ImagesModule from "./Modules/ImagesModule";
import HeaderLogo from "./HeaderLogo";
import LanguageMenu from "./LanguageMenu";
import { connect, MapStateToProps, ConnectedProps } from "react-redux";
import { getActiveSite } from "../selectors/sites";
import { getPictureById } from "../selectors/pictures";
import FullMenuButton from "./FullMenuButton";
import FullHeaderMenu from "./FullHeaderMenu";
import {
  getActivePagePath,
  getFallbackLanguage,
  getTopLevelMainMenu,
  getImageModuleWithFixedAspectRatio,
  keys,
  getActiveLogoId,
} from "../utils/utils";
import ClassNames from "classnames";
import HeaderActionLinks from "./HeaderActionLinks";

type ReduxProps = ConnectedProps<typeof connector>;

interface Props {
  imagesModule: TranslatedModule<ImagesModuleSettings>;
  queries: ContainerQueries;
  scheme: ColorScheme;
  isPreview: boolean;
  pageId: string;
  mainPageUrl: string | undefined;
  languageId: Language;
  actionLinks: ActionLinks;
  intersectionTriggerRef: RefCallback<HTMLElement>;
  logoId: string | undefined;
  logoBackground: HeaderLogoBackground;
  imageOverlayGradient: HeaderImageOverlayGradient;
}

interface StateProps {
  pages: PageState;
  accommodation: Accommodation | undefined;
  logo: Picture;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  menuItems: MainMenuItem[];
  isMultiLevel: boolean;
}

const HeaderWithFullOverlayMenu: FunctionComponent<Props & ReduxProps> = ({
  imagesModule,
  queries,
  scheme,
  isPreview,
  pageId,
  mainPageUrl,
  accommodation,
  logo,
  pages,
  languageId,
  actionLinks,
  activePagePath,
  fallbackLanguageId,
  menuItems,
  isMultiLevel,
  intersectionTriggerRef,
  logoBackground,
  imageOverlayGradient,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={ClassNames(
        "HeaderModule__Inner",
        "HeaderModule__Inner--wide",
        "HeaderModule__Inner--full-overlay-nav",
        `HeaderModule__Inner--image-overlay-gradient-${imageOverlayGradient}`,
        {
          "HeaderModule__Inner--full-height": !isPreview,
        }
      )}
    >
      <ImagesModule
        translatedModule={getImageModuleWithFixedAspectRatio(imagesModule)}
        isPreview={isPreview}
        pageId={pageId}
        queries={queries}
        isActive={false}
        isInsideHeader={true}
        isFirstOnPage={false}
        parentScheme={scheme}
        activeModuleId={undefined}
        fullHeight={!isPreview}
      />

      {isOpen && (
        <FullHeaderMenu
          fallbackLanguageId={fallbackLanguageId}
          isPreview={isPreview}
          pages={pages}
          menuItems={menuItems}
          languageId={languageId}
          onClose={() => setIsOpen(false)}
          activePagePath={activePagePath}
          isMultiLevel={isMultiLevel}
          actionLinks={actionLinks}
        />
      )}

      <div className="FullHeaderOverlay">
        <div className="FullHeaderOverlay__Inner">
          <HeaderLogo
            isPreview={isPreview}
            logo={logo}
            mainPageUrl={mainPageUrl}
            scheme={scheme}
            logoBackground={logoBackground}
          />
          <div className="FullHeaderOverlay__Box FullHeaderOverlay__Box--absolute">
            <HeaderActionLinks
              languageId={languageId}
              accommodation={accommodation}
              isPreview={isPreview}
              actionLinks={actionLinks}
            />

            <LanguageMenu
              isPreview={isPreview}
              languageId={languageId}
              scheme={undefined}
              pageId={pageId}
              variant="vertical"
            />
          </div>
          <FullMenuButton
            innerRef={intersectionTriggerRef}
            isOpen={false}
            languageId={languageId}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      </div>
    </div>
  );
};

const getIsMultiLevel = (pages: PageState): boolean => {
  const { null: topLevel, ...rest } = pages.byParentId;
  const parentPageIds = keys(rest).filter(
    (pageId) => (pages.byParentId[pageId] ?? []).length
  );

  return parentPageIds.length > 1;
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, sites, accommodation, mediaLibrary: { pictures, logoIds } },
  { languageId, pageId, isPreview, logoId }
): StateProps => {
  const site = getActiveSite(sites);
  const fallbackLanguageId = getFallbackLanguage(site, languageId);
  return {
    menuItems: getTopLevelMainMenu({
      pages,
      languageId,
      isPreview,
      fallbackLanguageId,
    }),
    pages,
    accommodation: accommodation[languageId],
    logo: getPictureById(pictures, getActiveLogoId(logoIds, logoId), {
      height: 100,
    }),
    activePagePath: getActivePagePath(pageId, pages.byParentId),
    fallbackLanguageId,
    isMultiLevel: getIsMultiLevel(pages),
  };
};

const connector = connect(mapStateToProps);

export default connector(HeaderWithFullOverlayMenu);
