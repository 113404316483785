import React, {
  FunctionComponent,
  MutableRefObject,
  ReactNode,
  useEffect,
  useState,
} from "react";
import ClassNames from "classnames";
import { EmblaCarouselType, EmblaOptionsType } from "embla-carousel";
import { useEmblaCarousel } from "embla-carousel/react";
import { SliderEffect } from "../types";

interface Props {
  slidesToShow: number;
  className?: string;
  containerClassName?: string;
  slideClassName?: string;
  sliderRef: MutableRefObject<EmblaCarouselType | undefined>;
  sliderEffect: SliderEffect;
  isPreview: boolean;
  slideIds: string[];
  activePreviewSlideId: string | undefined;
}

const EmblaCarouselSlide: FunctionComponent<{
  children: ReactNode;
  className: string | undefined;
  slidesToShow: number;
}> = ({ children, className, slidesToShow }) => (
  <div
    className={ClassNames("EmblaCarousel__Slide", className, {
      "EmblaCarousel__Slide--1/2": slidesToShow === 2,
      "EmblaCarousel__Slide--1/3": slidesToShow === 3,
      "EmblaCarousel__Slide--1/4": slidesToShow === 4,
      "EmblaCarousel__Slide--1/5": slidesToShow === 5,
      "EmblaCarousel__Slide--1/6": slidesToShow === 6,
    })}
  >
    {children}
  </div>
);

const isServer = typeof navigator === "undefined";

const Slider: FunctionComponent<Props> = ({
  children,
  className,
  containerClassName,
  slideClassName,
  sliderRef,
  slidesToShow: slidesToShowProp,
  sliderEffect,
  isPreview,
  activePreviewSlideId,
  slideIds,
}) => {
  const [isEmblaLoaded, setIsEmblaLoaded] = useState(false);
  const childrenArray = React.Children.toArray(children);

  // loop needs to be false with the fade effect,
  // otherwise it causes a glitch when navigating
  // with the arrows
  const options: EmblaOptionsType = {
    loop: sliderEffect === "slide",
    align: "start",
    draggable: !isPreview,
  };
  const slidesToShow = sliderEffect === "slide" ? slidesToShowProp : 1;

  const [emblaRef, emblaInstance] = useEmblaCarousel(options);
  sliderRef.current = emblaInstance;
  useEffect(() => {
    emblaInstance?.reInit(options);
  }, [slidesToShow, childrenArray.length]);

  useEffect(() => {
    emblaInstance?.reInit({ ...options, loop: sliderEffect === "slide" });
  }, [sliderEffect]);

  useEffect(() => {
    setIsEmblaLoaded(!!emblaInstance);
  }, [emblaInstance]);

  // Slide to active module in preview
  useEffect(() => {
    if (!activePreviewSlideId || !sliderRef.current) return;
    const index = slideIds.indexOf(activePreviewSlideId);
    index >= 0 && sliderRef.current.scrollTo(index);
  }, [activePreviewSlideId, sliderRef.current]);

  const carouselClassNames = ClassNames(
    "EmblaCarousel",
    { "EmblaCarousel--loaded": isEmblaLoaded },
    className
  );
  const containerClassNames = ClassNames(
    "EmblaCarousel__Container",
    { "EmblaCarousel__Container--fade": sliderEffect === "fade" },
    containerClassName
  );

  return isServer ? (
    <div className={carouselClassNames}>
      <div className={containerClassNames}>
        {childrenArray
          .map((child, index) => (
            <EmblaCarouselSlide
              key={index}
              className={slideClassName}
              slidesToShow={slidesToShow}
            >
              {child}
            </EmblaCarouselSlide>
          ))
          .slice(0, slidesToShow)}
      </div>
    </div>
  ) : (
    <div ref={emblaRef} className={carouselClassNames}>
      <div className={containerClassNames}>
        {childrenArray.map((child, index) => (
          <EmblaCarouselSlide
            key={index}
            className={slideClassName}
            slidesToShow={slidesToShow}
          >
            {child}
          </EmblaCarouselSlide>
        ))}
      </div>
    </div>
  );
};

export default Slider;
