import React from "react";
import { FunctionComponent } from "react";

const Spinner: FunctionComponent = () => (
  <div className="Spinner">
    <div className="Spinner__Bounce Spinner__Bounce--1"></div>
    <div className="Spinner__Bounce Spinner__Bounce--2"></div>
    <div className="Spinner__Bounce Spinner__Bounce--3"></div>
  </div>
);

export default Spinner;
