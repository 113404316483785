import React from "react";
import { FunctionComponent, useState, useEffect } from "react";
import {
  TranslatedModule,
  ModuleWithRichTextSettingsCombined,
  Language,
  ColorScheme,
  RichEditorProps,
} from "../types";
import { checkIsModuleWithDraftState, getLinkStyleClass } from "../utils/utils";
import ClassNames from "classnames";

interface Props {
  module: TranslatedModule<ModuleWithRichTextSettingsCombined>;
  pageId: string;
  languageId: Language;
  isPreview: boolean;
  // Link styling is not applied if scheme === undefined
  scheme: ColorScheme | undefined;
  maxCharacters?: number;
  className: string;
}

const RichEditorWrapper: FunctionComponent<Props> = ({
  module,
  pageId,
  isPreview,
  languageId,
  scheme,
  maxCharacters,
  className,
}) => {
  const [InnerComponent, setInnerComponent] = useState<
    FunctionComponent<RichEditorProps>
  >();
  const { description } = module.translation.settings;
  const linkStyleClass = scheme && getLinkStyleClass(scheme);

  useEffect(() => {
    isPreview &&
      import("./RichEditor").then((importedModule) =>
        setInnerComponent(importedModule.default)
      );
  }, [isPreview]);

  if (typeof description === "string") {
    return (
      <div
        className={ClassNames(className, linkStyleClass)}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    );
  }

  if (isPreview && checkIsModuleWithDraftState(module) && InnerComponent) {
    return (
      <div className={ClassNames(className, linkStyleClass)}>
        <InnerComponent
          module={module}
          pageId={pageId}
          languageId={languageId}
          isPreview={isPreview}
          maxCharacters={maxCharacters}
        />
      </div>
    );
  }

  return null;
};

export default RichEditorWrapper;
