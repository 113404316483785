import React from "react";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import ClassNames from "classnames";
import {
  ActionLinks,
  Language,
  PageState,
  ColorScheme,
  MainMenuItem,
  Picture,
} from "../types";
import HeaderLogo from "./HeaderLogo";
import PriorityNavigation from "./PriorityNavigation";
import HamburgerMenu from "./HamburgerMenu";
import LanguageMenu from "./LanguageMenu";
import ActionModuleNavigation from "./ActionModuleNavigation";

interface Props {
  actionLinks: ActionLinks;
  activePagePath: string[];
  hasHeaderImage: boolean;
  isPreview: boolean;
  languageId: Language;
  pages: PageState;
  scheme: ColorScheme;
  topLevelMainMenuItems: MainMenuItem[];
  fallbackLanguageId: Language | undefined;
  pageId: string;
  smallLogo: Picture;
  mainPageUrl: string | undefined;
}

const CompactHeaderBar: FunctionComponent<Props> = ({
  actionLinks,
  smallLogo,
  activePagePath,
  fallbackLanguageId,
  pages,
  hasHeaderImage,
  topLevelMainMenuItems,
  isPreview,
  languageId,
  mainPageUrl,
  pageId,
  scheme,
}) => (
  <div
    className={ClassNames(
      "CompactHeaderBar",
      style({
        backgroundColor: scheme.main.background,
        color: scheme.main.text,
      })
    )}
  >
    <HeaderLogo
      isPreview={isPreview}
      logo={smallLogo}
      mainPageUrl={mainPageUrl}
      scheme={scheme}
    />
    <PriorityNavigation
      className="MainMenu__Wrapper--in-header-nav MainMenu__Wrapper--horizontal"
      isPreview={isPreview}
      languageId={languageId}
      menuItems={topLevelMainMenuItems}
      pages={pages}
      scheme={scheme}
      showIcons={false}
      topHeaderVariant="in-header-nav"
      activePagePath={activePagePath}
      hasHeaderImage={hasHeaderImage}
      absolutePositioning={false}
      fallbackLanguageId={fallbackLanguageId}
    />
    <HamburgerMenu
      className="HeaderModule__Sticky__HamburgerMenu"
      activePagePath={activePagePath}
      isPreview={isPreview}
      languageId={languageId}
      pages={pages}
      scheme={scheme}
      topLevelMainMenuItems={topLevelMainMenuItems}
      fallbackLanguageId={fallbackLanguageId}
    />
    <LanguageMenu
      isPreview={isPreview}
      languageId={languageId}
      pageId={pageId}
      scheme={scheme}
      variant="horizontal"
    />
    <ActionModuleNavigation
      isPreview={isPreview}
      actionLinks={actionLinks}
      scheme={scheme}
      showQuickLinks={true}
    />
  </div>
);

export default CompactHeaderBar;
