import React from "react";
import { FunctionComponent } from "react";
import { OfferingsModuleSettings, BaseModuleProps } from "../../types";
import OfferingsModule from "./OfferingsModule";

interface Props extends BaseModuleProps<OfferingsModuleSettings> {}

const RoomsModule: FunctionComponent<Props> = (props) => {
  const moduleId = props.translatedModule.id;
  return (
    <OfferingsModule
      {...props}
      type="Rooms"
      urlHashPrefix={`#${moduleId}-rooms-`}
    />
  );
};

export default RoomsModule;
