import { createStore, applyMiddleware, compose, Reducer } from "redux";
import thunkMiddleware from "redux-thunk";
import { adminReducer } from "../reducers";
import storeReducer from "../reducers/store";
import { StoreState } from "../types";
import { AllActions } from "../actions";

declare var global: any;

export default function configureStore(initialState: StoreState) {
  const mainReducer: Reducer<StoreState, AllActions> = (state, action) => {
    if (action.type === "LOGOUT_USER") {
      state = initialState;
    }
    const adminReducerState = adminReducer(state, action);
    const storeReducerState = storeReducer(
      adminReducerState,
      action,
      initialState
    );

    return storeReducerState;
  };

  const store = (global.store = createStore(
    mainReducer,
    initialState,
    compose(
      applyMiddleware(thunkMiddleware),
      global.__REDUX_DEVTOOLS_EXTENSION__
        ? global.__REDUX_DEVTOOLS_EXTENSION__()
        : (f: unknown) => f
    )
  ));

  return store;
}
