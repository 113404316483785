import {
  WindowState,
  UsercentricsServiceName,
  UsercentricsEvent,
} from "../types";
import { isBrowser } from "./utils";

const eventName = "UsercentricsCustomEvent";

export const usercentricsDataProcessingServices = <const>[
  "Promotion",
  "Google Maps",
  "YouTube Video",
];

export const getUsercentricsServiceId = (
  serviceName: string
): string | undefined => {
  const { UC_UI } = (window as unknown) as WindowState;
  return UC_UI?.getServices().find(({ name }) => name === serviceName)?.id;
};

const getIsUsercentricsEvent = (event: Event): event is UsercentricsEvent =>
  (<UsercentricsEvent>event)?.detail?.event === "consent_status";

export const getUsercentricsConsent = (
  serviceName: UsercentricsServiceName
): boolean => {
  if (!isBrowser) return false;

  const { UC_UI } = (window as unknown) as WindowState;

  const consentStatus =
    UC_UI?.getServices().find(({ name }) => name === serviceName)?.consent
      .status ?? false;

  return consentStatus;
};

export const registerUserCentricsListener = (
  serviceName: UsercentricsServiceName,
  onConsented: () => void
) => {
  const onEvent = (event: Event) => {
    getIsUsercentricsEvent(event) && event.detail[serviceName] && onConsented();
  };

  window.addEventListener(eventName, onEvent);

  return {
    unmount: () => {
      window.removeEventListener(eventName, onEvent);
    },
  };
};
