import React from "react";
import { FunctionComponent, RefObject } from "react";
import MainMenuLevel from "./MainMenuLevel";
import { Language, PageState, MainMenuItem, ColorScheme } from "../types";
import ClassNames from "classnames";

interface Props {
  isPreview: boolean;
  languageId: Language;
  pages: PageState;
  menuItems: MainMenuItem[];
  scheme: ColorScheme;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  ulRef:
    | RefObject<HTMLUListElement>
    | ((instance: HTMLUListElement | null) => void)
    | undefined;
  className: string;
  liRef?: (instance: HTMLLIElement | null, index: number) => void;
}

const SplitMainMenuList: FunctionComponent<Props> = ({
  isPreview,
  languageId,
  pages,
  menuItems,
  scheme,
  activePagePath,
  fallbackLanguageId,
  ulRef,
  className,
  liRef,
  children,
}) => (
  <ul className={ClassNames(className, "MainMenu__List")} ref={ulRef}>
    {menuItems.map((currentMenuItem, index) => (
      <MainMenuLevel
        key={currentMenuItem.id}
        isPreview={isPreview}
        languageId={languageId}
        parentId={currentMenuItem.id}
        pages={pages}
        currentMenuItem={currentMenuItem}
        scheme={scheme}
        showIcons={false}
        menuLevel={0}
        topHeaderVariant={undefined}
        activePagePath={activePagePath}
        fallbackLanguageId={fallbackLanguageId}
        liRef={liRef && ((el) => liRef(el, index))}
        index={index}
        repeatFirstLevelItem={true}
      />
    ))}
    {children}
  </ul>
);

export default SplitMainMenuList;
