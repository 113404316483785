import React from "react";
import {
  FunctionComponent,
  useState,
  IframeHTMLAttributes,
  useEffect,
  useRef,
} from "react";
import { createPortal } from "react-dom";

interface Props
  extends Omit<
    IframeHTMLAttributes<HTMLIFrameElement>,
    "src" | "ref" | "srcDoc"
  > {
  onIframeLoaded: (iframe: HTMLIFrameElement) => void;
}

const Iframe: FunctionComponent<Props> = ({
  children,
  onIframeLoaded,
  ...props
}) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const rootEl = ref.current?.contentWindow?.document.getElementById("root");
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const onLoad = () => {
    setIframeLoaded(true);
    ref.current && onIframeLoaded(ref.current);
  };

  const removeEventListener = () =>
    ref.current?.removeEventListener("load", onLoad);

  useEffect(() => {
    ref.current?.addEventListener("load", onLoad);
    return removeEventListener;
  }, []);

  return (
    <iframe {...props} src="/base-iframe.html" ref={ref}>
      {rootEl && iframeLoaded && createPortal(<>{children}</>, rootEl)}
    </iframe>
  );
};

export default Iframe;
