import React from "react";
import { FunctionComponent } from "react";
import SmartLink from "./SmartLink";
import { Accommodation, ActionLinks, Language } from "../types";
import Icon from "./Icon";
import { getActionAndContactLinks, getTranslations } from "../utils/utils";
import globalTranslations from "../i18n";

interface Props {
  isPreview: boolean;
  actionLinks: ActionLinks;
  accommodation: Accommodation | undefined;
  languageId: Language;
}

const HeaderActionLinks: FunctionComponent<Props> = ({
  isPreview,
  actionLinks,
  accommodation,
  languageId,
}) => {
  const items = getActionAndContactLinks(accommodation, actionLinks);
  if (!items.length) return null;
  const mailTranslation = getTranslations(languageId, globalTranslations).email;

  return (
    <div className="HeaderActionLinks">
      <ul className="HeaderActionLinks__List">
        {items.map(({ children, icon, href }) => {
          const isMailLink = href?.indexOf("mailto:") === 0;

          return (
            <li className="HeaderActionLinks__ListItem" key={href + icon}>
              <SmartLink
                isPreview={isPreview}
                anchorAttrs={{
                  href,
                  className: "HeaderActionLinks__LinkItem",
                }}
              >
                <Icon glyph={icon} className="HeaderActionLinks__Icon" />
                <span className="HeaderActionLinks__Text">
                  {isMailLink ? mailTranslation : children}
                </span>
              </SmartLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default HeaderActionLinks;
