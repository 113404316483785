import { I18n } from "../types";
import React from "react";

const en: I18n = {
  boards: {
    0: "Not defined",
    1: "Only overnight",
    2: "Breakfast",
    3: "Half board",
    4: "Full board",
    5: "All inclusive",
  },
  email: "Email",
  more: "more",
  moreMenu: "more",
  languageNames: {
    de: "German",
    it: "Italian",
    en: "English",
    es: "Spanish",
    fr: "French",
    ru: "Russian",
    da: "Danish",
    pl: "Polish",
    nl: "Dutch",
  },
  menu: "menu",
  imprint: {
    author: "Author",
    icons: "Icons",
    pictures: "Photos",
  },
  errorOccurred: "An error has occurred. Please try again later.",
  countries: {
    main: "Quick selection",
    all: "All countries",
    error: "An error occurred while loading the country list.",
  },
  genders: {
    m: "Mr.",
    f: "Mrs./Ms.",
  },
  register: "Register",
  formFields: {
    gender: "Title",
    firstname: "First name",
    lastname: "Last name",
    email: "Email address",
    country: "Country",
  },
  newsletterMessages: {
    success: "Thank you for subscribing to our newsletter service!",
    error:
      "Unfortunately, the newsletter registration could not be completed. " +
      "Please try again later or send us an e-mail.",
    privacy: (privacyLink) => (
      <>
        By submitting to the newsletter service, I agree to the{" "}
        {privacyLink("terms and privacy policy")} and agree that my e-mail
        address is used for newsletter purposes.
      </>
    ),
  },
  on: "on",
};

export default en;
