import React from "react";
import { FunctionComponent, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  StoreState,
  PriceModuleSettings,
  BaseModuleProps,
  PricesWidgetSettings,
} from "../../types";
import { getActiveColorScheme, getSupportedLanguage } from "../../utils/utils";
import ModuleHeadings from "../ModuleHeadings";
import { getActiveSite } from "../../selectors/sites";
import ModuleWithHeadings from "../ModuleWithHeadings";
import RichEditorWrapper from "../RichEditorWrapper";
import PricesWidget from "../PricesWidget";

interface Props extends BaseModuleProps<PriceModuleSettings> {}

type ReduxProps = ConnectedProps<typeof connector>;

const PriceModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule,
  translatedModule: {
    id: moduleId,
    settings: { textAlign },
    translation: {
      settings: { title, subtitle },
      languageId,
    },
  },
  scheme,
  pageId,
  isPreview,
  isFirstOnPage,
  settings,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <ModuleWithHeadings
      innerRef={ref}
      title={title}
      subtitle={subtitle}
      id={moduleId}
      className={`PriceModule lazyload`}
      colors={{
        background: scheme.main.separator,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />

        <PricesWidget settings={settings} />

        <RichEditorWrapper
          module={translatedModule}
          pageId={pageId}
          languageId={languageId}
          isPreview={isPreview}
          scheme={scheme}
          className={`PriceModule__AdditionalInfos TextAlign--${textAlign.description}`}
        />
      </div>
    </ModuleWithHeadings>
  );
};

const mapStateToProps = (
  { colorSchemes, sites }: StoreState,
  { translatedModule }: Props
) => {
  const site = getActiveSite(sites);

  const {
    translation: { languageId },
    settings: { grouping, layout, roomIds, offerIds, boardIds },
  } = translatedModule;

  const settings: PricesWidgetSettings = {
    accommodationId: site.accommodation.id,
    lang: getSupportedLanguage(languageId, ["de", "it", "en", "fr"]),
    colorScheme: getActiveColorScheme(colorSchemes, site, translatedModule),
    grouping,
    layout,
    roomIds: roomIds.length ? roomIds : undefined,
    offerIds: offerIds.length ? offerIds : undefined,
    boardIds: boardIds.length ? boardIds : undefined,
  };

  return {
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
    settings,
  };
};

const connector = connect(mapStateToProps);

export default connector(PriceModule);
