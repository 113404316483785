import React from "react";
import { FunctionComponent } from "react";
import { TextAlign } from "../types";
import TextAlignSelect from "./TextAlignSelect";
import { getHtmlFieldId } from "../utils/utils";

interface Props {
  currentFormValue: string;
  currentTextAlignValue: TextAlign;
  label: string;
  onTextChange: (value: string) => void;
  onTextAlignChange: (value: TextAlign) => void;
}

const TextFormField: FunctionComponent<Props> = ({
  currentFormValue,
  currentTextAlignValue,
  label,
  onTextAlignChange,
  onTextChange,
}) => {
  const htmlId = getHtmlFieldId(label);
  return (
    <div className="Form__Field">
      <div className="Form__Content Form__Content--compact Form__Content--block">
        <div className="RichEditor">
          <div className="RichEditor__Toolbar">
            <TextAlignSelect
              onChange={onTextAlignChange}
              currentValue={currentTextAlignValue}
            />
          </div>
          <input
            id={htmlId}
            type="text"
            value={currentFormValue}
            onChange={(e) => {
              onTextChange(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="Form__Label">
        <label htmlFor={htmlId}>{label}</label>
      </div>
    </div>
  );
};

export default TextFormField;
