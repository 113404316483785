import React, { useEffect } from "react";
import { FunctionComponent, useState } from "react";
import {
  GuestnetWidgetModuleSettings,
  BaseModuleProps,
  StoreState,
  ColorScheme,
} from "../../types";
import { getActiveSite } from "../../selectors/sites";
import {
  getActiveColorScheme,
  getSupportedLanguage,
  isIE11,
} from "../../utils/utils";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import ModuleWithHeadings from "../ModuleWithHeadings";
import ModuleHeadings from "../ModuleHeadings";
import LazyloadWrapper from "../LazyloadWrapper";
import { injectScript } from "../../actions/LoadStates";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "gn-weekprogram": {
        "data-base-url": string;
        "data-color": string;
        "data-lang": string;
      };
    }
  }
}

type Props = BaseModuleProps<GuestnetWidgetModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const GuestnetWidgetModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign, baseUrl },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  injectScript,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);

  useEffect(() => {
    isLazyloaded &&
      baseUrl &&
      injectScript(
        "https://s3.eu-central-1.amazonaws.com/guestnet.info/widgets/weekprogram-1.1.js"
      );
  }, [isLazyloaded, baseUrl]);

  // Don’t show the Guestnet widget on IE11 because it’s not compatible with it
  if (isIE11) return null;

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="GuestnetWidgetModule"
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div className="Module__Wrapper">
          {baseUrl && (
            <gn-weekprogram
              data-base-url={baseUrl}
              data-color={scheme.main.separator}
              data-lang={getSupportedLanguage(languageId, ["de", "it", "en"])}
            ></gn-weekprogram>
          )}
          {!baseUrl && (
            <div className="GuestnetWidgetModule__Empty">Guestnet</div>
          )}
        </div>
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapDispatchToProps = {
  injectScript,
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(GuestnetWidgetModule);
