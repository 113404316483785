import React, { useEffect, useRef } from "react";
import { FunctionComponent, useState } from "react";
import {
  HogastPayModuleSettings,
  BaseModuleProps,
  StoreState,
  ColorScheme,
} from "../../types";
import { getActiveSite } from "../../selectors/sites";
import {
  getActiveColorScheme,
  getSupportedLanguage,
  insertScriptIntoHead,
} from "../../utils/utils";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import ModuleWithHeadings from "../ModuleWithHeadings";
import ModuleHeadings from "../ModuleHeadings";
import LazyloadWrapper from "../LazyloadWrapper";
import { injectScript } from "../../actions/LoadStates";
import { TypeStyle, createTypeStyle } from "typestyle";
import Iframe from "../Iframe";
import ClassNames from "classnames";
import { ResizeObserver } from "@juggle/resize-observer";
import { throttle } from "throttle-debounce";

type Props = BaseModuleProps<HogastPayModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const HogastPayModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign, key },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  isActive,
  isPreview,
}) => {
  const [styleInstance, setStyleInstance] = useState<TypeStyle>();
  const [isLazyloaded, setIsLazyloaded] = useState(false);
  const lang = getSupportedLanguage(languageId, ["de", "it", "en"]);
  const [iframeHeight, setIframeHeight] = useState(0);
  const outerObserverRef = useRef<ResizeObserver>();
  const innerObserverRef = useRef<ResizeObserver>();

  const onIframeLoaded = async (iframe: HTMLIFrameElement) => {
    const iframeDocument = iframe.contentDocument;
    if (!iframeDocument) return;
    const tag = document.createElement("style");
    iframeDocument.head.appendChild(tag);
    setStyleInstance(createTypeStyle(tag));

    const onResize = throttle(250, () => {
      setIframeHeight(iframeDocument.body.scrollHeight);
    });

    outerObserverRef.current = new ResizeObserver(onResize);
    outerObserverRef.current.observe(iframe);

    innerObserverRef.current = new ResizeObserver(onResize);
    innerObserverRef.current.observe(iframeDocument.body);

    await insertScriptIntoHead(
      `https://secure.hogast.it/rxdeposit/widget/${lang}?key=${key}`,
      iframeDocument
    );
  };

  // on unmount
  useEffect(() => {
    return () => {
      outerObserverRef.current?.disconnect();
      innerObserverRef.current?.disconnect();
    };
  }, []);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="HogastPayModule"
      colors={{
        background: scheme.main.separator,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div className="Module__Wrapper">
          {isLazyloaded && key && (
            <Iframe
              className={ClassNames("HogastPayModule__Iframe", {
                "HogastPayModule__Iframe--no-click": !isActive && isPreview,
              })}
              style={{ height: iframeHeight }}
              onIframeLoaded={onIframeLoaded}
              scrolling="no"
            >
              <div
                id="rxdeposit-container"
                className={styleInstance?.style({
                  $nest: {
                    "#hg-wi-form .h-wi-button": {
                      color: scheme.primary.text,
                      background: scheme.primary.background,
                      borderRadius: 0,
                    },
                    "#hg-wi-form .h-wi-button:hover": {
                      background: scheme.primary.background,
                    },
                    "#hg-wi-form": {
                      background: scheme.main.background,
                      maxWidth: "1200px",
                      margin: "0 auto",
                      padding: "3em 6%",
                    },
                    "#hg-wi-form .h-wi-footer .h-wi-text": {
                      color: scheme.main.text,
                    },
                    "#hg-wi-form .h-wi-required-text": {
                      color: scheme.main.text,
                    },
                    "#hg-wi-form .h-wi-content .h-wi-row .h-wi-value input": {
                      background: scheme.secondary.background,
                      color: scheme.secondary.text,
                      border: 0,
                      padding: "0.65em 0.75em",
                      fontSize: "1em",
                      height: "auto",
                    },
                    ".h-wi-amounts": {
                      background: scheme.secondary.background,
                      color: scheme.secondary.text,
                    },
                    "#hg-wi-form .h-wi-content .h-wi-row": {
                      background: "transparent",
                      borderBottom: 0,
                    },
                    "#hg-wi-form .h-wi-content .h-wi-row.h-wi-bold-row": {
                      background: "transparent",
                      borderBottom: 0,
                    },
                    "#hg-wi-form .h-wi-content .h-wi-row:not(:first-child)": {
                      marginTop: "1em",
                    },
                  },
                })}
              >
                <div id="hg-ti-form">
                  <div className="block">
                    <img src="https://secure.hogast.it/app_themes/deposit/loader.gif" />
                  </div>
                </div>
              </div>
            </Iframe>
          )}

          {!key && (
            <div className="HogastPayModule__Empty">Hogast Zahlknopf</div>
          )}
        </div>
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapDispatchToProps = {
  injectScript,
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(HogastPayModule);
