import React, { useEffect, useRef } from "react";
import { FunctionComponent } from "react";
import {
  HighlightsModuleSettings,
  FormModuleSettings,
  HighlightsDisplayType,
  IconName,
  SelectOption,
  HighlightsLayout,
} from "../../types";
import ModuleColorSchemeSelection from "../ModuleColorSchemeSelection";
import SubModulesList from "../SubModulesList";
import AspectRatioSelection from "../AspectRatioSelection";
import { connect, ConnectedProps } from "react-redux";
import TitleAndSubtitleSettings from "../TitleAndSubtitleSettings";
import { getMapDispatchToPropsForModuleSetting } from "../../utils/utils";
import NumberFormField from "../NumberFormField";
import { getMaxColumns } from "../MultiBox";
import isInt from "validator/lib/isInt";
import SelectFormField from "../SelectFormField";
import FormFieldRadios from "../FormFieldRadios";
import FormInfo from "../FormInfo";
import { AspectRatio } from "../../../server/types";
import Checkbox from "../Checkbox";
import ImageAlignmentSetting from "../ImageAlignmentSetting";

type ReduxProps = ConnectedProps<typeof connector>;

type Props = FormModuleSettings<HighlightsModuleSettings>;

interface DisplayType {
  value: HighlightsDisplayType;
  title: string;
  iconGlyphs: IconName;
}

const displayTypes: DisplayType[] = [
  {
    value: "list",
    title: "Liste",
    iconGlyphs: "separator",
  },
  {
    value: "slider",
    title: "Slider",
    iconGlyphs: "slider",
  },
];

const layouts: SelectOption<HighlightsLayout>[] = [
  { value: "layout-1", label: "Layout 1" },
  { value: "layout-2", label: "Layout 2" },
  { value: "layout-3", label: "Layout 3" },
  { value: "layout-4", label: "Layout 4" },
  { value: "layout-5", label: "Layout 5" },
  { value: "layout-6", label: "Layout 6" },
  { value: "layout-7", label: "Layout 7" },
  { value: "layout-8", label: "Layout 8" },
  { value: "layout-9", label: "Layout 9" },
];

const layoutDescriptions: { [layout in HighlightsLayout]?: string } = {
  "layout-4": "Dieses Layout ist für 3 Highlights geeignet.",
  "layout-5":
    "Dieses Layout ist für 2–4 Highlights geeignet. " +
    "Es werden nur maximal die ersten 4 Highlights angezeigt. " +
    "Wählen Sie ein anderes Layout, um über 4 Highlights anzuzeigen.",
  "layout-9": "Dieses Layout ist für mindestens 3 Highlights geeignet.",
};

export const getDynamicSettings = (layout: HighlightsLayout) => {
  const checkIncludesLayout = (
    layout: HighlightsLayout,
    layouts: HighlightsLayout[]
  ) => layouts.includes(layout);

  const reducedAspectRatios: AspectRatio[] = [0.75, 1, 1.3333];

  return {
    displayType: checkIncludesLayout(layout, [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-4",
      "layout-6",
      "layout-7",
      "layout-8",
    ]),
    imageAlignment: checkIncludesLayout(layout, ["layout-6", "layout-7"]),
    imagesAspectRatio: checkIncludesLayout(layout, [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-7",
    ]),
    maxColumnsCount: checkIncludesLayout(layout, [
      "layout-1",
      "layout-2",
      "layout-3",
      "layout-6",
      "layout-8",
    ]),
    highlightTextAlign: checkIncludesLayout(layout, [
      "layout-1",
      "layout-2",
      "layout-7",
      "layout-8",
    ]),
    description: checkIncludesLayout(layout, [
      "layout-1",
      "layout-2",
      "layout-6",
      "layout-7",
      "layout-8",
    ]),
    aspectRatios: checkIncludesLayout(layout, ["layout-7"])
      ? reducedAspectRatios
      : undefined,
    transparentSliderArrowBackground: checkIncludesLayout(layout, [
      "layout-4",
      "layout-6",
      "layout-7",
      "layout-8",
      "layout-9",
    ]),
    onlyOneButton: checkIncludesLayout(layout, [
      "layout-3",
      "layout-4",
      "layout-5",
    ]),
  };
};

const HighlightsModuleSettings: FunctionComponent<Props & ReduxProps> = ({
  translatedModule,
  translatedModule: {
    id: parentModuleId,
    siteId,
    settings: {
      displayType,
      imagesAspectRatio,
      layout,
      maxColumnsCount,
      textAlign,
      collapsedLinesCount,
      transparentSliderArrowBackground,
      imageAlignment,
    },
    translation: {
      settings: { title, subtitle },
    },
  },
  pageId,
  languageId,
  setModuleSetting,
}) => {
  const dynamicSettings = getDynamicSettings(layout);
  const useMaxColumnsCountFromPropsRef = useRef(false);

  useEffect(() => {
    const maxAllowedColumns = getMaxColumns(layout);
    (maxColumnsCount > maxAllowedColumns || maxColumnsCount < 1) &&
      setModuleSetting({
        global: { maxColumnsCount: maxAllowedColumns },
      });
  }, [layout, maxColumnsCount]);

  const maxColumns = getMaxColumns(layout);
  const isMaxColumnsCountValid = checkIsMaxColumnsCountValid({
    value: maxColumns,
    maxColumnsCount,
  });

  return (
    <>
      <TitleAndSubtitleSettings
        onTextAlignChange={(newTextAlign) => {
          setModuleSetting({
            global: {
              textAlign: {
                ...textAlign,
                ...newTextAlign,
              },
            },
          });
        }}
        onTitleAndSubtitleChange={({ title, subtitle }) => {
          setModuleSetting({
            language: { title, subtitle },
          });
        }}
        subtitle={subtitle}
        textAlign={textAlign}
        title={title}
      />

      <SelectFormField<HighlightsLayout>
        label="Layout"
        onChange={(value) => {
          const maxColumns = getMaxColumns(value);
          const isMaxColumnsCountValid = checkIsMaxColumnsCountValid({
            value: maxColumns,
            maxColumnsCount,
          });
          useMaxColumnsCountFromPropsRef.current = !isMaxColumnsCountValid;
          const { aspectRatios } = getDynamicSettings(value);
          setModuleSetting({
            global: {
              layout: value,
              imagesAspectRatio: aspectRatios?.[0] ?? imagesAspectRatio,
              maxColumnsCount:
                value === "layout-6" || value === "layout-8"
                  ? 1
                  : isMaxColumnsCountValid
                  ? maxColumnsCount
                  : maxColumns,
            },
          });
        }}
        value={layout}
        options={layouts}
      >
        {layoutDescriptions[layout] && (
          <FormInfo>{layoutDescriptions[layout]}</FormInfo>
        )}
      </SelectFormField>

      {dynamicSettings.maxColumnsCount && (
        <NumberFormField
          allowEmpty={false}
          errorMessage={
            "Die eingestellte Anzahl der Spalten liegt außerhalb des zulässigen Bereichs."
          }
          label="Boxen pro Zeile maximal"
          min={1}
          max={maxColumns}
          onChange={(value) =>
            setModuleSetting({
              global: { maxColumnsCount: value },
            })
          }
          value={maxColumnsCount}
          forceUpdateValue={() => {
            if (!useMaxColumnsCountFromPropsRef.current) return undefined;
            useMaxColumnsCountFromPropsRef.current = false;
            return maxColumnsCount;
          }}
          isValid={isMaxColumnsCountValid}
        />
      )}

      {dynamicSettings.displayType && (
        <FormFieldRadios<HighlightsDisplayType>
          radios={displayTypes}
          fieldLabel="Variante"
          denseLayout={true}
          onChange={(value) => {
            setModuleSetting({
              global: { displayType: value },
            });
          }}
          currentFormValue={displayType}
        />
      )}

      {dynamicSettings.transparentSliderArrowBackground &&
        displayType === "slider" && (
          <Checkbox
            checkedStatus={transparentSliderArrowBackground}
            htmlId="button-toggle-slider-arrows-background"
            text="Transparenter Hintergrund bei den Slider-Pfeilen"
            onChange={(value) => {
              setModuleSetting({
                global: {
                  transparentSliderArrowBackground: value,
                },
              });
            }}
          />
        )}

      <div className="Form__Field">
        <SubModulesList
          languageId={languageId}
          pageId={pageId}
          parentModuleId={parentModuleId}
          siteId={siteId}
          moduleType="HighlightModule"
        />
        <div className="Form__Label">
          <label>Highlights</label>
        </div>
      </div>

      {dynamicSettings.description && (
        <NumberFormField
          allowEmpty={true}
          errorMessage={
            "Die eingestellte Anzahl der Zeilen liegt außerhalb des zulässigen Bereichs."
          }
          label="Anzahl geöffnete Zeilen"
          min={3}
          max={100}
          onChange={(value) => {
            setModuleSetting({
              global: { collapsedLinesCount: value || undefined },
            });
          }}
          value={collapsedLinesCount}
        />
      )}

      {dynamicSettings.imagesAspectRatio && (
        <AspectRatioSelection
          onChange={(value) => {
            setModuleSetting({
              global: { imagesAspectRatio: value },
            });
          }}
          currentFormValue={imagesAspectRatio}
          allowedAspectRatios={dynamicSettings.aspectRatios}
        />
      )}

      {dynamicSettings.imageAlignment && (
        <ImageAlignmentSetting
          imageAlignment={imageAlignment}
          onChange={(value) => {
            setModuleSetting({
              global: {
                imageAlignment: value,
              },
            });
          }}
        />
      )}

      <ModuleColorSchemeSelection
        pageId={pageId}
        translatedModule={translatedModule}
      />
    </>
  );
};

const checkIsMaxColumnsCountValid = ({
  value,
  maxColumnsCount,
}: {
  value: number;
  maxColumnsCount: number;
}) =>
  isInt(maxColumnsCount.toString(), {
    min: 1,
    max: value,
  });

const mapDispatchToProps = getMapDispatchToPropsForModuleSetting<HighlightsModuleSettings>();

const connector = connect(undefined, mapDispatchToProps);

export default connector(HighlightsModuleSettings);
