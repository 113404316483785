import React from "react";
import { FunctionComponent, RefCallback } from "react";
import {
  TranslatedModule,
  ImagesModuleSettings,
  ContainerQueries,
  ColorScheme,
  Language,
  Accommodation,
  HeaderMenuVariant,
  ActionLinks,
} from "../types";
import ImagesModule from "./Modules/ImagesModule";
import SplitMainMenu from "./SplitMainMenu";
import HeaderActionLinks from "./HeaderActionLinks";
import HeaderActionBottomBar from "./HeaderActionBottomBar";
import ClassNames from "classnames";
import { getImageModuleWithFixedAspectRatio } from "../utils/utils";

interface Props {
  imagesModule?: TranslatedModule<ImagesModuleSettings>;
  isPreview: boolean;
  queries: ContainerQueries;
  pageId: string;
  scheme: ColorScheme;
  languageId: Language;
  activePagePath: string[];
  actionLinks: ActionLinks;
  accommodation: Accommodation | undefined;
  variant: HeaderMenuVariant;
  intersectionTriggerRef: RefCallback<HTMLElement>;
  showSouthTyrolLogo: boolean;
  logoId: string | undefined;
}

const HeaderWithSplitMenu: FunctionComponent<Props> = ({
  imagesModule,
  isPreview,
  pageId,
  queries,
  scheme,
  languageId,
  activePagePath,
  actionLinks,
  accommodation,
  variant,
  intersectionTriggerRef,
  showSouthTyrolLogo,
  logoId,
}) => (
  <div
    className={ClassNames(
      "HeaderModule__Inner",
      "HeaderModule__Inner--wide",
      `HeaderModule__Inner--menu-position-${variant}`,
      {
        "HeaderModule__Inner--full-height": !isPreview,
      }
    )}
  >
    <SplitMainMenu
      isPreview={isPreview}
      languageId={languageId}
      pageId={pageId}
      activePagePath={activePagePath}
      scheme={scheme}
      variant={variant}
      outerWrapperRef={intersectionTriggerRef}
      showSouthTyrolLogo={showSouthTyrolLogo}
      logoId={logoId}
    />

    {imagesModule && imagesModule.pageId && (
      <ImagesModule
        translatedModule={getImageModuleWithFixedAspectRatio(imagesModule)}
        isPreview={isPreview}
        pageId={pageId}
        queries={queries}
        isActive={false}
        isInsideHeader={true}
        isFirstOnPage={false}
        parentScheme={scheme}
        activeModuleId={undefined}
        fullHeight={!isPreview}
      />
    )}

    {variant === "top-bottom-bars" && (
      <HeaderActionBottomBar
        accommodation={accommodation}
        actionLinks={actionLinks}
        isPreview={isPreview}
        scheme={scheme}
      />
    )}

    {variant === "bottom-bar" && (
      <div className="HeaderModule__InnerOverlay HeaderModule__InnerOverlay--split-menu">
        <HeaderActionLinks
          languageId={languageId}
          accommodation={accommodation}
          isPreview={isPreview}
          actionLinks={actionLinks}
        />
      </div>
    )}
  </div>
);

export default HeaderWithSplitMenu;
