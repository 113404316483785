import React from "react";
import { FunctionComponent, MouseEvent } from "react";
import ClassNames from "classnames";
import {
  MainMenuItem,
  PageState,
  Language,
  ColorScheme,
  TopHeaderVariant,
} from "../types";
import MainMenuLevel from "./MainMenuLevel";
import { style } from "typestyle";

interface Props {
  menuItems: MainMenuItem[];
  parentMenuItem?: MainMenuItem;
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  scheme: ColorScheme | undefined;
  onMenuItemClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  showIcons: boolean;
  menuLevel?: number;
  topHeaderVariant: TopHeaderVariant | undefined;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  lastItemRightAlign?: boolean;
  repeatFirstLevelItem: boolean;
}

const getListStyleClass = (
  scheme: ColorScheme,
  topHeaderVariant: TopHeaderVariant | undefined,
  menuLevel: number
) => {
  if (topHeaderVariant === "hamburger-nav") {
    return undefined;
  }

  const css =
    menuLevel === 0
      ? {
          color: scheme.main.text,
        }
      : {
          backgroundColor: scheme.primary.background,
          color: scheme.primary.text,
        };

  return style({
    ...css,
    $nest: {
      "&::before": {
        borderBottomColor: scheme.primary.background,
      },
    },
  });
};

const MainMenuList: FunctionComponent<Props> = ({
  menuItems,
  parentMenuItem,
  isPreview,
  languageId,
  pages,
  scheme,
  onMenuItemClick,
  showIcons: showIconsProp,
  menuLevel = 0,
  topHeaderVariant,
  activePagePath,
  fallbackLanguageId,
  lastItemRightAlign,
  repeatFirstLevelItem,
}) => {
  // Only show the icons on the first level
  const showIcons = menuLevel === 0 ? showIconsProp : false;

  const repeatParentItem = Boolean(
    repeatFirstLevelItem &&
      parentMenuItem &&
      !parentMenuItem.isFolder &&
      menuLevel === 1
  );

  const listClassName = ClassNames("MainMenu__List", {
    MainMenu__ListLevel0: menuLevel === 0,
    MainMenu__ListLevel1: menuLevel === 1,
    MainMenu__ListLevel2up: menuLevel >= 2,
    "MainMenu__List--last-right-align": lastItemRightAlign,
    "MainMenu__List--parent-item-repeated": repeatParentItem,
  });

  if (!menuItems.length) return null;

  const combinedMenuItems = [
    ...(parentMenuItem && repeatParentItem ? [parentMenuItem] : []),
    ...menuItems,
  ];

  const listStyleClass =
    scheme && getListStyleClass(scheme, topHeaderVariant, menuLevel);

  return (
    <ul className={ClassNames(listClassName, listStyleClass, {})}>
      {combinedMenuItems.map((currentMenuItem, index) => (
        <MainMenuLevel
          key={currentMenuItem.id}
          isExpanded={!repeatParentItem || index !== 0}
          currentMenuItem={currentMenuItem}
          isPreview={isPreview}
          languageId={languageId}
          parentId={currentMenuItem.id}
          pages={pages}
          scheme={scheme}
          onMenuItemClick={onMenuItemClick}
          showIcons={showIcons}
          menuLevel={menuLevel}
          topHeaderVariant={topHeaderVariant}
          activePagePath={activePagePath}
          fallbackLanguageId={fallbackLanguageId}
          index={index}
          repeatFirstLevelItem={repeatFirstLevelItem}
        />
      ))}
    </ul>
  );
};
export default MainMenuList;
