import React from "react";
import { FunctionComponent } from "react";
import { BaseModuleProps, ColorScheme, StoreState } from "../../types";
import { getActiveColorScheme, getTranslations } from "../../utils/utils";
import { connect } from "react-redux";
import ModuleHeadings from "../ModuleHeadings";
import { getActiveSite } from "../../selectors/sites";
import ModuleWithHeadings from "../ModuleWithHeadings";
import { style } from "typestyle";
import ClassNames from "classnames";

export const translations = {
  de: {
    documentTitle: "Seite nicht gefunden",
    title: "Die von Ihnen gesuchte Seite wurde nicht gefunden",
    text: (email?: string) => (
      <>
        <p>
          Leider können wir kein Resultat für Ihre Suchanfrage finden – Ihren
          Urlaub zu einem besonderen Erlebnis machen können wir sehr wohl.
        </p>
        <p>
          Interessiert? Wir machen Ihnen gerne ein individuelles Angebot:{" "}
          <a href={"mailto:" + email}>Anfrage senden</a>
        </p>
        <p>
          Sie können auch gerne die Navigation über die Menüleiste verwenden um
          zur gewünschten Seite zurückzukehren.
        </p>
      </>
    ),
  },
  it: {
    documentTitle: "Pagina non trovata",
    title: "La pagina da Lei richiesta non è stata trovata",
    text: (email?: string) => (
      <>
        <p>
          Purtroppo non siamo in grado di trovare risultati per la ricerca da
          Lei effettuata – ma siamo in grado di rendere la Sua vacanza
          indimenticabile.
        </p>{" "}
        <p>
          Le interessa? Siamo felici di proporLe un’offerta individuale:{" "}
          <a href={"mailto:" + email}>Invia richiesta</a>
        </p>
        <p>
          Per tornare alla pagina precedente, può servirsi degli strumenti di
          navigazione nella barra del menu.
        </p>
      </>
    ),
  },
  en: {
    documentTitle: "Page not found",
    title: "The page you were looking for could not be found ",
    text: (email?: string) => (
      <>
        <p>
          Unfortunately we could not find any results matching your search – but
          we can make your holiday unforgettable.
        </p>{" "}
        <p>
          Are you interested? We will be glad to send you an individual offer:{" "}
          <a href={"mailto:" + email}>Send request</a>
        </p>
        <p>
          To go back to previous page you can also use the navigation buttons on
          the menu bar.
        </p>
      </>
    ),
  },
};

interface StateProps {
  scheme: ColorScheme;
  email: string | undefined;
}

const NotFoundTextModule: FunctionComponent<BaseModuleProps & StateProps> = ({
  scheme,
  translatedModule: {
    id,
    translation: { languageId },
  },
  email,
}) => {
  const i18n = getTranslations(languageId, translations);
  return (
    <ModuleWithHeadings
      title={i18n.title}
      subtitle={undefined}
      id={id}
      className="TextModule"
      colors={{ background: scheme.main.background }}
    >
      <div className="TextModule__Wrapper TextModule__Wrapper--image-left Module__Wrapper Module__Wrapper--default-padding">
        <div
          className={ClassNames(
            "TextModule__Content",
            style({
              color: scheme.main.text,
            })
          )}
        >
          <div className="TextModule__Text">
            <ModuleHeadings
              scheme={scheme}
              isFirstOnPage={true}
              textAlign={{ title: "center", subtitle: "center" }}
              title={i18n.title}
              subtitle={undefined}
            />
            <section className="TextModule__Description TextAlign--center">
              {i18n.text(email)}
            </section>
          </div>
        </div>
      </div>
    </ModuleWithHeadings>
  );
};

const mapStateToProps = (
  { colorSchemes, accommodation, sites }: StoreState,
  { translatedModule }: BaseModuleProps
) => {
  const site = getActiveSite(sites);

  const currentAccommodation =
    accommodation[translatedModule.translation.languageId];

  const email = currentAccommodation && currentAccommodation.email;

  return {
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
    email,
  };
};

export default connect(mapStateToProps)(NotFoundTextModule);
