import React from "react";
import { FunctionComponent } from "react";
import { TitleAndSubtitleTextAlign, ColorScheme } from "../types";
import ClassNames from "classnames";
import MultilineText from "./MultilineText";
import { style } from "typestyle";

interface Props {
  isFirstOnPage: boolean;
  textAlign: TitleAndSubtitleTextAlign;
  className?: string;
  title: string;
  subtitle: string | undefined;
  scheme: ColorScheme;
}

const ModuleHeadings: FunctionComponent<Props> = ({
  isFirstOnPage,
  className,
  textAlign,
  title,
  subtitle,
  scheme,
}) => {
  const TagName = isFirstOnPage ? "h1" : "h2";
  const SubtitleTagName = title ? "div" : TagName;
  if (!title && !subtitle) return null;
  return (
    <div className="Module__Headings">
      {title && (
        <TagName
          className={ClassNames(
            "Module__Title",
            `TextAlign--${textAlign.title}`,
            className,
            style({ color: scheme.main.title })
          )}
        >
          {title}
        </TagName>
      )}
      {subtitle && (
        <SubtitleTagName
          className={ClassNames(
            "Module__Subtitle",
            `TextAlign--${textAlign.subtitle}`,
            style({ color: scheme.main.text })
          )}
        >
          <MultilineText text={subtitle} />
        </SubtitleTagName>
      )}
    </div>
  );
};

export default ModuleHeadings;
