import React from "react";
import { FunctionComponent } from "react";
import { getActiveColorScheme, getSupportedLanguage } from "../../utils/utils";
import { connect, MapStateToProps, ConnectedProps } from "react-redux";
import {
  StoreState,
  QuickEnquiryModuleSettings,
  BaseModuleProps,
  QuickEnquiryWidgetSettings,
} from "../../types";
import { getActiveSite } from "../../selectors/sites";
import tinycolors from "tinycolor2";
import QuickEnquiryWidget from "../QuickEnquiryWidget";

interface Props extends BaseModuleProps<QuickEnquiryModuleSettings> {}

interface StateProps {
  settings: QuickEnquiryWidgetSettings;
}

type ReduxProps = ConnectedProps<typeof connector>;

const EnquiryModule: FunctionComponent<Props & ReduxProps> = ({
  settings,
  isActive,
  isPreview,
}) =>
  // Don’t render anything before accommodation is loaded
  // Hide the module if it’s in preview and inactive
  !settings.accommodationId || (isPreview && !isActive) ? null : (
    <QuickEnquiryWidget settings={settings} />
  );

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule, isPreview }
): StateProps => {
  const site = getActiveSite(sites);

  const {
    translation: { languageId },
    settings: { layout, fieldSettings, badgeStyle },
  } = translatedModule;

  const scheme = getActiveColorScheme(colorSchemes, site, translatedModule);

  const settings: QuickEnquiryWidgetSettings = {
    fieldSettings,
    accommodationId: site.accommodation.id,
    lang: getSupportedLanguage(languageId, ["de", "it", "en", "fr"]),
    layout,
    badgeStyle,
    // Show form by default in preview (CMS)
    onLoad: ({ showForm }) => showForm(isPreview),
    colorScheme: {
      ...scheme,
      main: {
        ...scheme.main,
        separator: tinycolors(scheme.main.separator).lighten(2).toRgbString(),
      },
    },
  };

  return {
    settings,
  };
};

const connector = connect(mapStateToProps);

export default connector(EnquiryModule);
