import React, {
  FunctionComponent,
  useRef,
  useEffect,
  MouseEvent,
  useState,
  useCallback,
} from "react";
import {
  ColorScheme,
  TranslatedModule,
  ImagesModuleSettings,
  ImageModuleSettings,
  OverlayModuleSettings,
} from "../types";
import Slider from "./Slider";
import ImageModule from "./Modules/ImageModule";
import OverlayModule from "./Modules/OverlayModule";
import { EmblaCarouselType } from "embla-carousel";
import Icon from "./Icon";

interface Props {
  pageId: string;
  images: TranslatedModule<ImageModuleSettings>[];
  isPreview: boolean;
  isInsideHeader: boolean;
  scheme: ColorScheme;
  translatedModule: TranslatedModule<ImagesModuleSettings>;
  overlayModule: TranslatedModule<OverlayModuleSettings> | undefined;
  activeModuleId: string | undefined;
  subModuleIsActive: boolean;
  slidesToShow: number;
}

type ArrowComponent = FunctionComponent<{
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}>;

const PrevArrow: ArrowComponent = ({ onClick }) => (
  <button
    onClick={onClick}
    className="ContentSlider__Control--prev ContentSlider__Control"
  >
    <Icon className="ContentSlider__Control__Icon" glyph="arrow-left" />
  </button>
);

const NextArrow: ArrowComponent = ({ onClick }) => (
  <button
    onClick={onClick}
    className="ContentSlider__Control--next ContentSlider__Control"
  >
    <Icon className="ContentSlider__Control__Icon" glyph="arrow-right" />
  </button>
);

const ImageSlider: FunctionComponent<Props> = ({
  images,
  isPreview,
  translatedModule: {
    settings: {
      imagesAspectRatio,
      autoPlay,
      autoPlayDelay,
      sliderEffect,
      columnsCount,
    },
  },
  scheme,
  pageId,
  overlayModule,
  activeModuleId,
  subModuleIsActive,
  slidesToShow,
  isInsideHeader,
}) => {
  const sliderRef = useRef<EmblaCarouselType>();
  const [currentIndex, setCurrentIndex] = useState(0);

  const showArrows = images.length > slidesToShow;

  useEffect(() => {
    sliderRef.current?.on("select", () =>
      setCurrentIndex(sliderRef.current?.selectedScrollSnap() ?? 0)
    );
  }, [sliderRef.current]);

  const runAutoplay = useCallback(() => {
    const slider = sliderRef.current;
    slider?.canScrollNext() ? slider.scrollNext() : slider?.scrollTo(0);
  }, [sliderRef.current]);

  const { play, stop } = useRecursiveTimeout(runAutoplay, autoPlayDelay * 1000);

  useEffect(() => {
    sliderRef.current?.on("pointerDown", stop);
  }, [stop]);

  useEffect(() => {
    (!autoPlay || subModuleIsActive) && stop();
    !subModuleIsActive && autoPlay && play();
  }, [play, stop, autoPlay, subModuleIsActive]);

  return (
    <div className="SliderModule">
      {showArrows && (
        <PrevArrow
          onClick={() => {
            stop();
            const slider = sliderRef.current;
            if (!slider) return;
            const slidesCount = slider.slideNodes().length;
            slider.canScrollPrev()
              ? slider.scrollPrev()
              : slider.scrollTo(slidesCount);
          }}
        />
      )}
      <Slider
        isPreview={isPreview}
        sliderRef={sliderRef}
        slidesToShow={slidesToShow}
        sliderEffect={sliderEffect}
        activePreviewSlideId={activeModuleId}
        slideIds={images.map(({ id }) => id)}
      >
        {images.map((image, index) => (
          <div className="SliderModule__Slide" key={image.id}>
            {(index <= currentIndex + columnsCount ||
              sliderEffect === "slide") && (
              <ImageModule
                key={image.id}
                aspectRatio={imagesAspectRatio}
                translatedModule={image}
                width={1920}
                // don’t lazy load the first header image
                lazyLoad={!(index === 0 && isInsideHeader)}
                sizes="100vw"
                isPreview={isPreview}
                isSlider={true}
                pageId={pageId}
                scheme={scheme}
                showOverlay={!overlayModule}
              />
            )}
          </div>
        ))}
      </Slider>
      {showArrows && (
        <NextArrow
          onClick={() => {
            stop();
            sliderRef.current?.canScrollNext()
              ? sliderRef.current?.scrollNext()
              : sliderRef.current?.scrollTo(0);
          }}
        />
      )}

      {overlayModule && (
        <OverlayModule
          isPreview={isPreview}
          scheme={scheme}
          isSlider={true}
          translatedModule={overlayModule}
          pageId={pageId}
        />
      )}
    </div>
  );
};

const useRecursiveTimeout = (callback: () => void, delay: number) => {
  const [isRunning, setIsRunning] = useState(false);
  const stop = useCallback(() => setIsRunning(false), [setIsRunning]);
  const play = useCallback(() => setIsRunning(true), [setIsRunning]);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!isRunning) return;
    let id: NodeJS.Timeout;

    const tick = () => {
      if (!isRunning) return clearTimeout(id);
      savedCallback.current();
      requestAnimationFrame(() => (id = setTimeout(tick, delay)));
    };
    requestAnimationFrame(() => (id = setTimeout(tick, delay)));

    return () => {
      if (id) clearTimeout(id);
      stop();
    };
  }, [isRunning, delay, stop]);

  return { play, stop };
};

export default ImageSlider;
