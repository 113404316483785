import React from "react";
import { FunctionComponent, useRef, useState } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import {
  StoreState,
  ColorScheme,
  BaseModuleProps,
  OfferingsWidgetSettings,
  APIOffering,
  OfferingsModuleSettings,
  OfferingsType,
} from "../../types";
import {
  getActiveColorScheme,
  getSupportedLanguage,
  isDefined,
} from "../../utils/utils";
import { getActiveSite } from "../../selectors/sites";
import {
  getEnquiryFieldSettings,
  getBookingFieldSettings,
} from "../../selectors/modules";
import ModuleWithHeadings from "../ModuleWithHeadings";
import OfferingsWidget from "../OfferingsWidget";
import ModuleHeadings from "../ModuleHeadings";
import Spinner from "../Spinner";
import ClassNames from "classnames";

type Props = BaseModuleProps<OfferingsModuleSettings> & {
  type: OfferingsType;
  urlHashPrefix: string;
};

interface StateProps {
  scheme: ColorScheme;
  offeringsWidgetSettings: OfferingsWidgetSettings;
}

type ReduxProps = ConnectedProps<typeof connector>;

const getOfferingIdFromHash = (urlHashPrefix: string): number | undefined => {
  // For server side rendering
  if (typeof location === "undefined") return undefined;
  const [, hashId] = location.hash.split(urlHashPrefix);
  const offeringId = +hashId;
  return isNaN(offeringId) ? undefined : offeringId;
};

type OfferingQueryParam = "roomId" | "offerId";

const offeringParamsMap: {
  [offeringsType in OfferingsType]: OfferingQueryParam;
} = {
  Rooms: "roomId",
  Specials: "offerId",
};

const getOfferingIdFromQuery = (
  offeringsType: OfferingsType,
  moduleId: string
): number | undefined => {
  if (typeof location === "undefined") return undefined;
  const key = offeringParamsMap[offeringsType];
  const searchParams = new URLSearchParams(window.location.search);

  const query = {
    roomId: searchParams.get("roomId") ?? undefined,
    offerId: searchParams.get("offerId") ?? undefined,
    moduleId: searchParams.get("moduleId") ?? undefined,
  };
  const offeringId = query[key];
  return query.moduleId !== moduleId ||
    offeringId === undefined ||
    isNaN(+offeringId)
    ? undefined
    : +offeringId;
};

const filterOfferings = ({
  pinnedItems,
  maxItems,
  offerings,
}: {
  pinnedItems: number[];
  maxItems: number | undefined;
  offerings: APIOffering[];
}): APIOffering[] => {
  if (!pinnedItems.length) {
    return offerings.slice(0, maxItems || undefined);
  }

  const byId = offerings.reduce<{ [key in number]?: APIOffering }>(
    (acc, offering) => {
      acc[offering.id] = offering;
      return acc;
    },
    {}
  );

  const allIds = offerings.map(({ id }) => id);

  const idsNotInPinnedItems = allIds.filter(
    (offeringId) => pinnedItems.indexOf(offeringId) === -1
  );

  const orderedIds = [
    ...pinnedItems,
    ...(pinnedItems.length ? idsNotInPinnedItems : []),
  ];

  const filteredOfferings = orderedIds
    .map((id) => byId[id])
    .filter(isDefined)
    .slice(0, maxItems ?? undefined);

  return filteredOfferings;
};

const OfferingModule: FunctionComponent<Props & ReduxProps> = ({
  isPreview,
  isFirstOnPage,
  offeringsWidgetSettings,
  scheme,
  translatedModule: {
    id: moduleId,
    translation: {
      settings: { title, subtitle },
    },
    settings: { textAlign, pinnedItems, maxItems },
  },
  type,
  urlHashPrefix,
}) => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
  const [hasOfferings, setHasOfferings] = useState(true);
  const offeringIdFromQuery = useRef(getOfferingIdFromQuery(type, moduleId))
    .current;
  const offeringIdFromHash = useRef(getOfferingIdFromHash(urlHashPrefix))
    .current;
  const offeringIdFromUrl = offeringIdFromQuery ?? offeringIdFromHash;

  const onWidgetLoad: OfferingsWidgetSettings["onLoad"] = (
    { openDetails },
    offerings
  ) => {
    setIsWidgetLoaded(true);
    offeringIdFromUrl && openDetails(offeringIdFromUrl);

    const filteredOfferings = filterOfferings({
      maxItems,
      pinnedItems,
      offerings: offerings,
    });

    setHasOfferings(!!filteredOfferings.length);

    return filteredOfferings;
  };

  const onDetailsOpen: OfferingsWidgetSettings["onDetailsOpen"] = ({ id }) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("moduleId", moduleId);
    searchParams.set(offeringParamsMap[type], String(id));

    history.pushState(
      null,
      "",
      `${location.pathname}?${searchParams.toString()}`
    );
  };

  const onDetailsClose: OfferingsWidgetSettings["onDetailsClose"] = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(offeringParamsMap[type]);
    searchParams.delete("moduleId");
    const searchString = searchParams.toString();

    history.pushState(
      null,
      "",
      `${location.pathname}${searchString ? "?" : ""}${searchString}`
    );
  };

  // Hide the whole module on generated site if there’s no special
  if (!isPreview && !hasOfferings) return null;

  return (
    <ModuleWithHeadings
      id={moduleId}
      colors={{
        background: scheme.main.separator,
      }}
      className="OfferingsModule"
      title={title}
      subtitle={subtitle}
    >
      {!isWidgetLoaded && offeringIdFromUrl !== undefined && (
        <div className="OfferingsModule__DetailsLoading">
          <Spinner />
        </div>
      )}
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>
      <div
        className={ClassNames({
          Module__Wrapper: !(offeringsWidgetSettings.layout === "layout-4"),
        })}
      >
        <OfferingsWidget
          type={type}
          lazyload={offeringIdFromUrl === undefined}
          pinnedItems={pinnedItems}
          maxItems={maxItems}
          settings={{
            ...offeringsWidgetSettings,
            onLoad: onWidgetLoad,
            onDetailsOpen,
            onDetailsClose,
          }}
        />
      </div>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites, modules },
  {
    translatedModule,
    translatedModule: {
      settings: {
        displayType,
        imageAspectRatio,
        layout,
        maxColumns,
        bookingLayout,
        enquiryLayout,
        themeIds,
      },
      translation: { languageId },
      parentId,
    },
  }
): StateProps => {
  const site = getActiveSite(sites);

  const isInsidePopUpModule = parentId
    ? modules.byId[parentId]?.type === "PopUpModule"
    : false;

  const colorScheme = getActiveColorScheme(
    colorSchemes,
    site,
    translatedModule
  );

  const enquiryFieldSettings = getEnquiryFieldSettings(modules, languageId);
  const bookingFieldSettings = getBookingFieldSettings(modules, languageId);

  return {
    scheme: colorScheme,
    offeringsWidgetSettings: {
      colorScheme,
      accommodationId: site.accommodation.id,
      imageAspectRatio: imageAspectRatio,
      layout,
      displayType: isInsidePopUpModule ? "slides" : displayType,
      lang: getSupportedLanguage(languageId, ["de", "it", "en", "fr"]),
      themeIds,
      maxColumns: isInsidePopUpModule ? 1 : maxColumns,
      bookingSettings: {
        layout: isInsidePopUpModule ? "4-steps" : bookingLayout,
        fieldSettings: bookingFieldSettings,
      },
      enquirySettings: {
        layout: isInsidePopUpModule ? "layout-1" : enquiryLayout,
        fieldSettings: enquiryFieldSettings,
      },
    },
  };
};

const connector = connect(mapStateToProps);

export default connector(OfferingModule);
