import React from "react";
import { FunctionComponent } from "react";
import { IconName } from "../types";
import Icon from "./Icon";
import ClassNames from "classnames";

interface Props {
  icon: IconName;
  title: string;
  onClick: () => void;
  isActive?: boolean;
}

const ItemsListButton: FunctionComponent<Props> = ({
  title,
  icon,
  onClick,
  isActive = false,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={ClassNames("Btn Btn--compact ItemsListItem__Btn", {
      "Btn--active": isActive,
    })}
    title={title}
  >
    <Icon glyph={icon} />
  </button>
);

export default ItemsListButton;
