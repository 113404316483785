import React, { ReactNode } from "react";
import { ReactElement, ReactText } from "react";
import { getHtmlFieldId } from "../utils/utils";
import { SelectOption } from "../types";

interface Props<OptionValues> {
  value: OptionValues;
  label: string;
  options: SelectOption<OptionValues>[];
  onChange: (value: OptionValues) => void;
  children?: ReactNode;
}

const SelectFormField: <OptionValues extends ReactText | undefined>(
  props: Props<OptionValues>
) => ReactElement<Props<OptionValues>> = ({
  label,
  value,
  onChange,
  options,
  children,
}) => {
  const htmlId = getHtmlFieldId(label);

  return (
    <div className="Form__Field">
      <div className="Form__Content">
        <select
          id={htmlId}
          value={value}
          onChange={(e) => {
            const val = e.target.value;
            onChange((isNaN(+val) ? val : +val) as typeof value);
          }}
        >
          {options.map(({ value, label }) => {
            return (
              <option key={value + label} value={value}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
      {children}
      <div className="Form__Label">
        <label htmlFor={htmlId}>{label}</label>
      </div>
    </div>
  );
};

export default SelectFormField;
