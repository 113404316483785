import React from "react";
import { connect, MapStateToProps, ConnectedProps } from "react-redux";
import { FunctionComponent } from "react";
import {
  StoreState,
  TextModuleSettings,
  ColorScheme,
  BaseModuleProps,
  ButtonModuleSettings,
  TranslatedModule,
  ImageModuleSettings,
} from "../../types";
import {
  getActiveColorScheme,
  getTranslatedButtonModules,
  getImageSubModule,
} from "../../utils/utils";
import ModuleHeadings from "../ModuleHeadings";
import { getActiveSite } from "../../selectors/sites";
import ButtonModule from "./ButtonModule";
import ImageModule from "./ImageModule";
import ModuleWithHeadings from "../ModuleWithHeadings";
import RichEditorWrapper from "../RichEditorWrapper";
import ClassNames from "classnames";
import { style } from "typestyle";

interface Props extends BaseModuleProps<TextModuleSettings> {}

interface StateProps {
  image: TranslatedModule<ImageModuleSettings> | undefined;
  scheme: ColorScheme;
  buttons: TranslatedModule<ButtonModuleSettings>[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const TextModule: FunctionComponent<Props & ReduxProps> = ({
  image,
  translatedModule,
  translatedModule: {
    settings: {
      textAlign,
      imagesAspectRatio,
      imageAlignment,
      width,
      boxAlign,
      columns,
    },
    id,
    translation: {
      languageId,
      settings: { subtitle, title },
    },
  },
  buttons,
  scheme,
  isPreview,
  pageId,
  isFirstOnPage,
}) => (
  <ModuleWithHeadings
    title={title}
    subtitle={subtitle}
    id={id}
    colors={{ background: scheme.main.background }}
    className="TextModule"
  >
    <div
      className={ClassNames(
        `Module__Wrapper Module__Wrapper--default-padding TextModule__Wrapper FlexAlignH--${boxAlign}`
      )}
    >
      <div
        className={ClassNames(
          "TextModule__WrapperInner",
          `TextModule__WrapperInner--${imageAlignment}`,
          {
            "TextModule__WrapperInner--narrow": width === "narrow",
          }
        )}
      >
        {image && (
          <div className="TextModule__Image">
            <ImageModule
              aspectRatio={imagesAspectRatio}
              lazyLoad={true}
              translatedModule={image}
              width={600}
              sizes="(min-width: 640px) 40vw, (min-width: 1600px) 600px, 100vw"
              isPreview={isPreview}
              isSlider={false}
              pageId={pageId}
              scheme={scheme}
              showOverlay={false}
            />
          </div>
        )}
        <div
          className={ClassNames(
            "TextModule__Content",
            style({
              color: scheme.main.text,
            })
          )}
        >
          <div className="TextModule__Text">
            <ModuleHeadings
              scheme={scheme}
              isFirstOnPage={isFirstOnPage}
              textAlign={textAlign}
              title={title}
              subtitle={subtitle}
            />

            <RichEditorWrapper
              className={ClassNames(
                "TextModule__Description",
                `TextAlign--${textAlign.description}`,
                { "TextModule__Description--columns-2": columns === 2 }
              )}
              module={translatedModule}
              pageId={pageId}
              languageId={languageId}
              isPreview={isPreview}
              scheme={scheme}
            />
          </div>
          {buttons.length > 0 && (
            <div
              className={`ButtonGroup ButtonGroup--align-${textAlign.buttons}`}
            >
              {buttons.map((button) => (
                <ButtonModule
                  key={button.id}
                  scheme={scheme}
                  isPreview={isPreview}
                  translatedModule={button}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  </ModuleWithHeadings>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules, colorSchemes, sites },
  {
    translatedModule,
    translatedModule: {
      id: moduleId,
      translation: { languageId },
    },
    pageId,
  }
): StateProps => {
  const site = getActiveSite(sites);

  return {
    image: getImageSubModule({ languageId, pageId, moduleId, modules }),
    buttons: getTranslatedButtonModules({
      modules,
      moduleId,
      languageId,
      pageId,
    }),
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
  };
};

const connector = connect(mapStateToProps);

export default connector(TextModule);
