import React, { FunctionComponent } from "react";
import { ImageAlignment } from "../types";
import FormFieldRadios from "./FormFieldRadios";

interface Props {
  imageAlignment: ImageAlignment;
  onChange: (value: ImageAlignment) => void;
}

const ImageAlignmentSetting: FunctionComponent<Props> = ({
  imageAlignment,
  onChange,
}) => (
  <FormFieldRadios<ImageAlignment>
    denseLayout={false}
    fieldLabel="Bildausrichtung"
    currentFormValue={imageAlignment}
    onChange={onChange}
    radios={[
      {
        value: "image-left",
        title: "Bild links",
        iconGlyphs: ["image", "text"],
      },
      {
        value: "image-right",
        title: "Bild rechts",
        iconGlyphs: ["text", "image"],
      },
    ]}
  />
);

export default ImageAlignmentSetting;
