import React from "react";
import { FunctionComponent } from "react";
import { connect, MapStateToProps, ConnectedProps } from "react-redux";
import {
  StoreState,
  BaseModuleProps,
  WeatherModuleSettings,
  WeatherWidgetSettings,
  ColorScheme,
} from "../../types";
import ModuleHeadings from "../ModuleHeadings";
import { getActiveSite } from "../../selectors/sites";
import ModuleWithHeadings from "../ModuleWithHeadings";
import WeatherWidget from "../WeatherWidget";
import ClassNames from "classnames";

interface Props extends BaseModuleProps<WeatherModuleSettings> {
  scheme: ColorScheme;
}

interface StateProps {
  settings: WeatherWidgetSettings;
}

type ReduxProps = ConnectedProps<typeof connector>;

const WeatherModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    id,
    settings: { textAlign },
    translation: {
      settings: { title, subtitle },
    },
  },
  settings,
  isFirstOnPage,
}) => (
  <ModuleWithHeadings
    title={title}
    subtitle={subtitle}
    id={id}
    className={ClassNames(
      "WeatherModule",
      `WeatherModule--align-${textAlign.description}`
    )}
    colors={{
      background: settings.colorScheme.main.background,
    }}
  >
    <ModuleHeadings
      scheme={settings.colorScheme}
      isFirstOnPage={isFirstOnPage}
      textAlign={textAlign}
      title={title}
      subtitle={subtitle}
    />

    <WeatherWidget settings={settings} />
  </ModuleWithHeadings>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { sites },
  { translatedModule, scheme }
): StateProps => {
  const site = getActiveSite(sites);

  const {
    translation: { languageId },
    settings: { districtId, iconStyle },
  } = translatedModule;

  return {
    settings: {
      accommodationId: site.accommodation.id,
      lang: languageId,
      colorScheme: scheme,
      districtId,
      iconStyle,
    },
  };
};

const connector = connect(mapStateToProps);

export default connector(WeatherModule);
