import React from "react";
import { FunctionComponent, useRef } from "react";
import {
  ContainerQueries,
  HighlightsLayout,
  HighlightsDisplayType,
  ColorScheme,
  HighlightsItem,
  AspectRatio,
  TitleAndSubtitleTextAlign,
  Language,
  RichEditorState,
} from "../types";
import MultiBoxItem from "./MultiBoxItem";
import ClassNames from "classnames";
import MultiBoxTile from "./MultiBoxTile";
import Slider from "./Slider";
import MultiBoxSliderArrow from "./MultiBoxSliderArrow";
import ModuleHeadings from "./ModuleHeadings";
import { EmblaCarouselType } from "embla-carousel";
import { style } from "typestyle";

const calcSlidesPerView = (
  queries: ContainerQueries | undefined,
  layout: HighlightsLayout,
  maxColumnsCount: number
): number => {
  if (layout === "layout-2") {
    return queries && queries["Query--large"] ? maxColumnsCount : 1;
  }

  if (!queries) return maxColumnsCount;
  if (queries["Query--xxlarge"]) return Math.min(4, maxColumnsCount);
  if (queries["Query--large"]) return Math.min(3, maxColumnsCount);
  if (queries["Query--medium"]) return Math.min(2, maxColumnsCount);

  return 1;
};

const isTileLayout = (layout: HighlightsLayout) => layout === "layout-3";

interface Props {
  layout: HighlightsLayout;
  scheme: ColorScheme;
  displayType: HighlightsDisplayType;
  imagesAspectRatio: AspectRatio;
  queries?: ContainerQueries;
  items: HighlightsItem[];
  isPreview: boolean;
  isFirstOnPage: boolean;
  contentOpenOnIndex?: number;
  activeModuleId: string | undefined;
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  maxColumnsCount: number;
  placeholdersShown: boolean;
  languageId: Language;
  collapsedLinesCount: number | undefined;
  richEditor: RichEditorState;
}

export const getMaxColumns = (layout: HighlightsLayout): number => {
  switch (layout) {
    case "layout-1":
      return 4;
    case "layout-2":
      return 2;
    case "layout-3":
      return 4;
    case "layout-6":
      return 2;
    case "layout-8":
      return 2;
  }

  return 0;
};

const MultiBox: FunctionComponent<Props> = ({
  items,
  layout,
  scheme,
  displayType: settingsDisplayType,
  queries,
  isPreview,
  imagesAspectRatio,
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  maxColumnsCount,
  placeholdersShown,
  languageId,
  collapsedLinesCount,
  richEditor,
  activeModuleId,
}) => {
  const sliderRef = useRef<EmblaCarouselType>();
  const goToPrevSlide = () => sliderRef.current?.scrollPrev();
  const goToNextSlide = () => sliderRef.current?.scrollNext();

  const slidesPerView = calcSlidesPerView(queries, layout, maxColumnsCount);
  const displayType: HighlightsDisplayType =
    items.length > slidesPerView && settingsDisplayType === "slider"
      ? "slider"
      : "list";
  const hasSlider = displayType === "slider";

  const multiBoxItems = items.map((item) => {
    const { id, title, subtitle, picture, link, imageSettings } = item;

    return isTileLayout(layout) ? (
      <MultiBoxTile
        key={id}
        title={title}
        subtitle={subtitle}
        picture={picture}
        link={link}
        imagesAspectRatio={imagesAspectRatio}
        imageSettings={imageSettings}
        isPreview={isPreview}
        tilesCount={items.length}
      />
    ) : (
      <MultiBoxItem
        key={id}
        item={item}
        scheme={scheme}
        imagesAspectRatio={imagesAspectRatio}
        isPreview={isPreview}
        isPlaceholderItem={placeholdersShown}
        languageId={languageId}
        collapsedLinesCount={collapsedLinesCount}
        isEditing={!!richEditor[id]}
      />
    );
  });

  return (
    <div
      className={ClassNames(
        "MultiBox",
        `MultiBox--${layout}`,
        `MultiBox--${displayType}`,
        `MultiBox--count-${items.length}`,
        {
          "MultiBox--center": items.length < 3,
          [`MultiBox--max-columns-count-${maxColumnsCount}`]:
            displayType === "list",
        },
        style({
          background: scheme.main.separator,
        })
      )}
    >
      <div className="MultiBox__Wrapper Module__Wrapper Module__Wrapper--small-padding">
        <div
          className={ClassNames("MultiBox__WrapperOuter", {
            "MultiBox__WrapperOuter--narrow":
              layout === "layout-1" && maxColumnsCount === 2,
          })}
        >
          <ModuleHeadings
            scheme={scheme}
            isFirstOnPage={isFirstOnPage}
            textAlign={textAlign}
            title={title}
            subtitle={subtitle}
          />
          {hasSlider && (
            <>
              <MultiBoxSliderArrow
                scheme={scheme}
                type="prev"
                imagesAspectRatio={imagesAspectRatio}
                slidesPerView={slidesPerView}
                onClick={goToPrevSlide}
              />
              <MultiBoxSliderArrow
                scheme={scheme}
                type="next"
                imagesAspectRatio={imagesAspectRatio}
                slidesPerView={slidesPerView}
                onClick={goToNextSlide}
              />
              <div className="MultiBox__Wrapper__Inner">
                <Slider
                  isPreview={isPreview}
                  sliderRef={sliderRef}
                  className="MultiBox__Slider"
                  containerClassName="MultiBox__SliderContainer"
                  slideClassName="MultiBox__SliderSlide"
                  slidesToShow={slidesPerView}
                  sliderEffect="slide"
                  activePreviewSlideId={activeModuleId}
                  slideIds={items.map(({ id }) => id)}
                >
                  {multiBoxItems}
                </Slider>
              </div>
            </>
          )}

          {!hasSlider && (
            <div className="MultiBox__Wrapper__Inner">{multiBoxItems}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiBox;
