import React from "react";
import { FunctionComponent } from "react";
import Button from "./Button";
import { ColorScheme, ActionLinks, ActionLinkType } from "../types";
import ClassNames from "classnames";
import {
  actionModuleLinkTypes,
  isDefined,
  quickLinkTypes,
} from "../utils/utils";
import SmartLink from "./SmartLink";
import Icon from "./Icon";
import { style } from "typestyle";

interface Props {
  scheme: ColorScheme | undefined;
  isPreview: boolean;
  actionLinks: ActionLinks;
  className?: string;
  onItemClick?: () => void;
  showQuickLinks: boolean;
}

interface Link {
  href: string;
  label: string;
  type: ActionLinkType;
}

const ActionModuleNavigation: FunctionComponent<Props> = ({
  scheme,
  isPreview,
  actionLinks,
  className,
  onItemClick,
  showQuickLinks,
}) => {
  const moduleLinks = getActionModuleLinks(actionLinks);
  const quickLinks = getQuickLinks(actionLinks);

  return (
    <div className={ClassNames("ActionModuleNavigation", className)}>
      {showQuickLinks && (
        <div className="ActionModuleNavigation__Quick">
          {quickLinks.map(({ label, href, type }) => (
            <SmartLink
              key={type}
              anchorAttrs={{
                className: "ActionModuleNavigation__QuickItem",
                href,
                title: label,
              }}
              isPreview={isPreview}
            >
              <Icon
                className={ClassNames(
                  "ActionModuleNavigation__Icon",
                  style({ fill: scheme?.primary.background })
                )}
                glyph={type}
              />
            </SmartLink>
          ))}
        </div>
      )}

      {moduleLinks.map(({ label, href, type }, index) => (
        <Button
          className="ActionModuleNavigation__Item"
          key={index}
          type={type === "enquiry" ? "secondary" : "primary"}
          anchorAttrs={{
            href,
            onClick: onItemClick,
          }}
          scheme={scheme}
          isPreview={isPreview}
        >
          {label}
        </Button>
      ))}
    </div>
  );
};

const getLink = (
  actionLinks: ActionLinks,
  type: ActionLinkType
): Link | undefined => {
  const link = actionLinks[type];
  return (
    link && {
      href: link.href,
      label: link.label,
      type,
    }
  );
};

const getActionModuleLinks = (actionLinks: ActionLinks) =>
  actionModuleLinkTypes
    .map<Link | undefined>((type) => getLink(actionLinks, type))
    .filter(isDefined);

const getQuickLinks = (actionLinks: ActionLinks) =>
  quickLinkTypes
    .map<Link | undefined>((type) => getLink(actionLinks, type))
    .filter(isDefined);

export default ActionModuleNavigation;
