import React from "react";
import { FunctionComponent } from "react";
import {
  HighlightsModuleSettings,
  ColorScheme,
  StoreState,
  HighlightsItem,
  HighlightModuleSettings,
  Modules,
  Pictures,
  BaseModuleProps,
  Language,
  TranslatedModule,
  ImageModuleSettings,
  PageState,
  RichEditorState,
  HighlightsLayout,
} from "../../types";
import MultiBox from "../MultiBox";
import { connect, MapStateToProps } from "react-redux";
import {
  getActiveColorScheme,
  getModulesByParentId,
  resolveButtonLink,
  getTranslatedButtonModules,
  getTranslatedModulesByParentId,
} from "../../utils/utils";
import { defaultSettings } from "../../reducers/modules/byId";
import deepExtend from "deep-extend";
import { getActiveSite } from "../../selectors/sites";
import ButtonModule from "./ButtonModule";
import { getPictureById } from "../../selectors/pictures";
import { APISite } from "../../../server/types";
import ModuleWithHeadings from "../ModuleWithHeadings";
import RichEditorWrapper from "../RichEditorWrapper";
import ClassNames from "classnames";
import HighlightsLayout4 from "../HighlightsLayout4";
import HighlightsLayout5 from "../HighlightsLayout5";
import HighlightsLayout6 from "../HighlightsLayout6";
import HighlightsLayout7 from "../HighlightsLayout7";
import HighlightsLayout8 from "../HighlightsLayout8";
import HighlightsLayout9 from "../HighlightsLayout9";
import { getDynamicSettings } from "../ModuleSettings/HighlightsModuleSettings";

type Props = BaseModuleProps<HighlightsModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
  items: HighlightsItem[];
  hasHighlights: boolean;
  richEditor: RichEditorState;
}

const HighlightsModule: FunctionComponent<Props & StateProps> = ({
  translatedModule: {
    settings: {
      textAlign,
      maxColumnsCount,
      collapsedLinesCount,
      displayType,
      imagesAspectRatio,
      layout,
      transparentSliderArrowBackground,
      imageAlignment,
    },
    translation: {
      settings: { title, subtitle },
      languageId,
    },
    id,
  },
  queries,
  scheme,
  items,
  isPreview,
  isFirstOnPage,
  hasHighlights,
  richEditor,
  activeModuleId,
}) => {
  const layoutsForMultiBox: HighlightsLayout[] = [
    "layout-1",
    "layout-2",
    "layout-3",
  ];
  const isLayoutForMultiBox = layoutsForMultiBox.includes(layout);

  return (
    <ModuleWithHeadings
      id={id}
      className="HighlightsModule"
      title={title}
      subtitle={subtitle}
      colors={{ color: scheme.main.text }}
    >
      {layout === "layout-4" && (
        <HighlightsLayout4
          {...{
            isFirstOnPage,
            scheme,
            subtitle,
            textAlign,
            title,
            items,
            queries,
            displayType,
            isPreview,
            activeModuleId,
            transparentSliderArrowBackground,
          }}
        />
      )}

      {layout === "layout-5" && (
        <HighlightsLayout5
          {...{
            isFirstOnPage,
            scheme,
            subtitle,
            textAlign,
            title,
            items,
            queries,
            isPreview,
          }}
        />
      )}

      {layout === "layout-6" && (
        <HighlightsLayout6
          {...{
            isFirstOnPage,
            scheme,
            subtitle,
            textAlign,
            title,
            items,
            collapsedLinesCount,
            languageId,
            richEditor,
            isPreview,
            displayType,
            activeModuleId,
            transparentSliderArrowBackground,
            imageAlignment,
            maxColumnsCount,
            queries,
          }}
        />
      )}

      {layout === "layout-7" && (
        <HighlightsLayout7
          {...{
            isFirstOnPage,
            scheme,
            subtitle,
            textAlign,
            title,
            items,
            queries,
            collapsedLinesCount,
            languageId,
            richEditor,
            isPreview,
            displayType,
            imagesAspectRatio,
            activeModuleId,
            transparentSliderArrowBackground,
            imageAlignment,
          }}
        />
      )}

      {layout === "layout-8" && (
        <HighlightsLayout8
          {...{
            isFirstOnPage,
            scheme,
            subtitle,
            textAlign,
            title,
            items,
            queries,
            collapsedLinesCount,
            languageId,
            richEditor,
            isPreview,
            activeModuleId,
            maxColumnsCount,
            displayType,
            transparentSliderArrowBackground,
          }}
        />
      )}

      {layout === "layout-9" && (
        <HighlightsLayout9
          {...{
            isFirstOnPage,
            scheme,
            subtitle,
            textAlign,
            title,
            items,
            queries,
            isPreview,
            activeModuleId,
            transparentSliderArrowBackground,
          }}
        />
      )}

      {isLayoutForMultiBox && (
        <MultiBox
          {...{
            title,
            subtitle,
            textAlign,
            imagesAspectRatio,
            isPreview,
            queries,
            scheme,
            layout,
            languageId,
            displayType,
            items,
            isFirstOnPage,
            maxColumnsCount,
            placeholdersShown: !hasHighlights,
            collapsedLinesCount: collapsedLinesCount,
            richEditor,
            activeModuleId,
          }}
        />
      )}
    </ModuleWithHeadings>
  );
};

const getDefaultItemsCount = (layout: HighlightsLayout): number => {
  switch (layout) {
    case "layout-4":
      return 3;
    case "layout-5":
      return 4;
    case "layout-9":
      return 3;
    default:
      return 2;
  }
};

const getItems = ({
  pictures,
  modules,
  module,
  pageId,
  languageId,
  isPreview,
  scheme,
  pages,
  site,
}: {
  pictures: Pictures;
  modules: Modules;
  module: TranslatedModule<HighlightsModuleSettings>;
  pageId: string;
  languageId: Language;
  isPreview: boolean;
  scheme: ColorScheme;
  pages: PageState;
  site: APISite;
}): { items: HighlightsItem[]; hasHighlights: boolean } => {
  const {
    siteId,
    settings: { layout },
  } = module;
  const highlights = getTranslatedModulesByParentId<HighlightModuleSettings>({
    modules,
    parentId: module.id,
    pageId,
    moduleType: "HighlightModule",
    languageId,
  });

  const hasHighlights = !!highlights.length;

  const finalHighlights = hasHighlights
    ? highlights
    : // Construct Array with sequential numbers as strings
      Object.keys(Array.from(new Array(getDefaultItemsCount(layout)))).map<
        TranslatedModule<HighlightModuleSettings>
      >((id) => {
        const highlightModule: TranslatedModule<HighlightModuleSettings> = {
          id,
          colorSchemeId: null,
          siteId,
          type: "HighlightModule",
          pageId,
          parentId: module.id,
          settings: deepExtend({}, defaultSettings.HighlightModule.global),
          translation: {
            languageId,
            exists: true,
            settings: deepExtend({}, defaultSettings.HighlightModule.language),
          },
          languages: [languageId],
        };
        return highlightModule;
      });

  // TODO: check if pictures are loaded
  // Use getPictureById to retrieve pictures and crop informations!
  const items: HighlightsItem[] = finalHighlights.map((highlight) => {
    const {
      id: moduleId,
      settings: { textAlign },
      translation: {
        settings: { title, subtitle },
      },
    } = highlight;

    const images = getModulesByParentId<ImageModuleSettings>(
      modules,
      moduleId,
      pageId,
      "ImageModule"
    );

    const imageSettings = images.length ? images[0].settings : undefined;

    const picture = getPictureById(
      pictures,
      imageSettings && imageSettings.pictureId
    );

    const translatedButtons = getTranslatedButtonModules({
      languageId,
      modules,
      moduleId,
      pageId,
    });

    const buttons = translatedButtons.map((translatedButton) => (
      <ButtonModule
        key={translatedButton.id}
        translatedModule={translatedButton}
        scheme={scheme}
        isPreview={isPreview}
      />
    ));

    const firstButton = translatedButtons[0];
    const link =
      firstButton &&
      resolveButtonLink({
        isPreview,
        pages,
        site,
        translatedModule: firstButton,
      });

    const dynamicSettings = getDynamicSettings(layout);

    const item: HighlightsItem = {
      id: moduleId,
      title,
      subtitle,
      textAlign,
      picture,
      link,
      text: (
        <RichEditorWrapper
          module={highlight}
          pageId={pageId}
          languageId={languageId}
          isPreview={isPreview}
          scheme={scheme}
          className={ClassNames({
            [`TextAlign--${textAlign.description}`]: dynamicSettings.highlightTextAlign,
          })}
        />
      ),
      buttons,
      imageSettings,
    };

    return item;
  });

  return { items, hasHighlights };
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, mediaLibrary, modules, sites, pages, richEditor },
  { translatedModule, isPreview, pageId }
): StateProps => {
  const site = getActiveSite(sites);
  const { languageId } = translatedModule.translation;
  const { pictures } = mediaLibrary;
  const scheme = getActiveColorScheme(colorSchemes, site, translatedModule);

  const { items, hasHighlights } = getItems({
    pictures,
    site,
    pages,
    modules,
    module: translatedModule,
    pageId,
    languageId,
    isPreview,
    scheme,
  });

  return {
    scheme,
    items,
    hasHighlights,
    richEditor,
  };
};

export default connect(mapStateToProps)(HighlightsModule);
