import { MediaLibrary, Picture, Pictures } from "../types";
import { AllActions } from "../actions";
import { keys, removeFromArray } from "../utils/utils";
import { Reducer } from "redux";

const initialState: MediaLibrary = {
  pictures: {},
  logoIds: [],
  groups: [],
  picturesByGroupId: {},
};

const getAllMediaSuccess = (
  state: MediaLibrary,
  pictures: Pictures
): MediaLibrary => {
  const pictureKeys = keys(pictures);
  const logoIds = pictureKeys.filter(
    (key) => pictures[key].category === "logo"
  );
  return { ...state, pictures, logoIds };
};

const getMediaGroupSuccess = ({
  state,
  groupId,
  pictures,
}: {
  state: MediaLibrary;
  groupId: number;
  pictures: Pictures;
}): MediaLibrary => {
  const pictureKeys = keys(pictures);

  return {
    ...state,
    picturesByGroupId: {
      ...state.picturesByGroupId,
      [groupId]: pictureKeys,
    },
  };
};

const uploadMediaSuccess = (
  state: MediaLibrary,
  picture: Picture
): MediaLibrary => ({
  ...state,
  logoIds: [...state.logoIds, picture.id],
  pictures: {
    ...state.pictures,
    [picture.id]: picture,
  },
});

const deleteMediaSuccess = (state: MediaLibrary, id: string): MediaLibrary => {
  const newPictures = { ...state.pictures };
  delete newPictures[id];

  return {
    ...state,
    logoIds: removeFromArray(state.logoIds, id),
    pictures: newPictures,
  };
};

const reducer: Reducer<MediaLibrary, AllActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "GET_ALL_MEDIA_SUCCESS":
      return getAllMediaSuccess(state, action.pictures);

    case "GET_ALL_MEDIA_GROUPS_SUCCESS":
      return { ...state, groups: action.groups };

    case "GET_MEDIA_GROUP_SUCCESS":
      return getMediaGroupSuccess({
        state,
        groupId: action.groupId,
        pictures: action.pictures,
      });

    case "UPLOAD_MEDIA_SUCCESS":
      return uploadMediaSuccess(state, action.picture);

    case "DELETE_MEDIA_SUCCESS":
      return deleteMediaSuccess(state, action.id);

    default:
      return state;
  }
};

export default reducer;
