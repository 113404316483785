import React from "react";
import { FunctionComponent } from "react";
import { Accommodation, ActionLinks, NavLinkWithIcon } from "../types";
import Icon from "./Icon";
import ClassNames from "classnames";
import {
  getContactLinks,
  getNavLinkWithIconFromActionLink,
  isDefined,
  quickLinkTypes,
} from "../utils/utils";

interface Props {
  accommodation: Accommodation | undefined;
  actionLinks: ActionLinks;
  className?: string;
}

const ContactNav: FunctionComponent<Props> = ({
  accommodation,
  className,
  actionLinks,
}) => {
  const links = [
    ...getQuickLinksWithIcon(actionLinks),
    ...getContactLinks(accommodation),
  ];

  if (!links.length) return null;

  return (
    <ul className={ClassNames("ContactNav", className)}>
      {links.map(({ href, children, icon }) => (
        <li
          key={href}
          className={ClassNames("ContactNav__ListItem", {
            "ContactNav__ListItem--quick":
              icon === "specials" || icon === "vouchers",
          })}
        >
          <a className="ContactNav__Item" href={href}>
            <Icon className="ContactNav__Icon" glyph={icon} />
            <span className="ContactNav__ItemText">{children}</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

const getQuickLinksWithIcon = (actionLinks: ActionLinks) =>
  quickLinkTypes
    .map<NavLinkWithIcon | undefined>((type) => {
      const actionLink = actionLinks[type];
      return actionLink && getNavLinkWithIconFromActionLink(actionLink, type);
    })
    .filter(isDefined);

export default ContactNav;
